export const initialState = {
    user: null,
    playing: false,
    converstation:  null,
}

export const actionTypes = {
    COVERSTATION: "COVERSTATION",
    SET_USER: 'SET_USER',
    PLAING: 'PLAING'
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user
            }
        case actionTypes.COVERSTATION:
            return {
                ...state,
                converstation: action.converstation
        }
        case actionTypes.PLAING:
            return {
                ...state,
                playing: action.playing
            }
        default:
            return state
    }
}

export default stateReducer