import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyCpHHeNKBarxv8Dmn7NaYSRR-0gz_pSND4",
  authDomain: "social-81b0c.firebaseapp.com",
  projectId: "social-81b0c",
  storageBucket: "social-81b0c.appspot.com",
  messagingSenderId: "946458771630",
  appId: "1:946458771630:web:a96794c72cc0444f032d3f",
  measurementId: "G-V9ZG0PCKKD"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore()


export default db