import React, {useState, useEffect, useContext} from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import LinearProgress from '@material-ui/core/LinearProgress'
import UserItem from './/UserItem'
import '../SearchUser/SearchUser.css'

import db from '../../firebase'

import {Action} from '../../contexts/ChatReducers' 
import {ChatContext} from '../../contexts/ChatContextProvider'
import {useStateValue} from '../../contexts/StateContextProvider'

const SearchUser = () => {
    const [search, setSearch] = useState('')
    const [existingRooms, setExistingRooms] = useState([])
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [{user}] = useStateValue()
    const {rooms, dispatch} = useContext(ChatContext)

    let memberList = []
    let uniqueMemberList = []

    // const [uniqueMemberLists, setUniqueMemberLists] = useState(uniqueMemberList)

    const onClickResult = (room) => {
        dispatch({
            type: Action.ADD_ROOM,
            room: room
        })
    }

    const onChipDelete = (room) => deleteChip(room)

    const deleteChip = (room) => {
        dispatch({
            type: Action.REMOVE_ROOM,
            id : room.id
        })
    }

    useEffect(() => {
    //    const unsubscribe = db.collection('rooms').where('members', 'array-contains', user.id).onSnapshot(snapshot => 
    //       setExistingRooms(snapshot.docs.map((doc)=>{
    //           return {
    //               id:doc.id,
    //               ...doc.data()
    //            }
    //        }))
    //    )
//
   //     return () => {
    //        unsubscribe()
    //    }
    return
    }, [])

    existingRooms.map(room=>room.members.forEach(member=> memberList.push(member)))
    memberList.map(member=>{
        return uniqueMemberList.indexOf(member)===-1 && uniqueMemberList.push(member)
    })

    const isClicked = (user) => rooms.some(item => item.id === user.id)
    
    // useEffect(() => {
    //     existingRooms.map(room=>room.members.forEach(member=> memberList.push(member)))
    //     memberList.map(member=>{
    //         return uniqueMemberList.indexOf(member)===-1 && uniqueMemberList.push(member)
    //     })
    //     setUniqueMemberLists(uniqueMemberList)
    // }, [])


    return (
        <div className="searchUser">           
           <div className="users__result">
           {
               existingRooms.filter(res=>!uniqueMemberList.includes(res.id)).map(resUser => {
                    return   <UserItem key={resUser.id}
                                    roomId  = {resUser.id}
                                    members = {resUser.members}   
                                    clicked = {isClicked(resUser)}
                                    onDelete = {()=> onChipDelete(resUser)}     
                                    onClick = {() => onClickResult(resUser)}       
                   />
               })
           }
           </div>        

        </div>
    )
}

export default SearchUser
