import React, {useEffect, useState,useRef,useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import firebase from 'firebase'
import {useParams} from 'react-router'
import './Chat.css'
import {Avatar} from '@material-ui/core'

import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined'
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined'
import SendIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'
import CropIcon from '@material-ui/icons/Crop'
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'

import Popover from '@material-ui/core/Popover'
import { Button, Flex } from 'antd';
import Picker from 'emoji-picker-react'
import axios from "axios"
import StatusInput from '../StatusInput/StatusInput'
import CropPhoto from '../EditPhoto/CropPhotoB'
import AddALT from '../EditPhoto/AddALT'
import Modal from '../../elements/Modal/Modal'
import TabbarMenu from '../../elements/TabbarMenu/TabbarMenu'
import Spinner from '../../elements/Spinner/Spinner'
import MessageItem from '../../components/MessageItem/MessageItem'
import apiClient from '../../utils/api'
import db from '../../firebase'
import postToCloudinary from '../../helpers/postToCloudinary'
import {useStateValue} from '../../contexts/StateContextProvider'
import {generateAltText} from '../../helpers/generateAltText'
import {getInfo} from '../../helpers/getImageDimension'
import moment from "moment"
import {useRoomState} from '../../contexts/IsRoomOpenedContextProvider'
import {actionTypes} from '../../contexts/IsRoomOpenedReducers'
import styled from 'styled-components'

const Chat = ({ setHidden,socket }) => {
   const {roomId} = useParams()
   const [messages, setMessages] = useState([])
   const [text, setText] = useState('')
   const msg = useRef()
   const inputRef = useRef(null)
   const [members, setMembers] = useState('')
   const [memberssub, setMemberssub] = useState('')
   const [membersis, setMembersIs] = useState()
   const [display, setDisplay] = useState([])
   const [contact, setContact] = useState(null)
   const [mediaRecorder, setMediaRecorder] = useState(null);
   const [{user}] = useStateValue()
   const [isRecording, setisRecording] = useState(false);
   const [{RoomData}, dispatch] = useRoomState()
   const [recorder,setRecorder] = useState()
   const history = useHistory()
   const [timeZone,setTimeZone] = useState()

   const [altText, setAltText] = useState(generateAltText(user.displayName))
   const [anchorEl, setAnchorEl] = useState(null)
   const [isLoading, setIsloading] = useState(false)
   const isMobile = useMediaQuery({ maxWidth: 914 })


   const [src, setSrc] = useState(null)
   const [url,setUrl] = useState("")
   const [voiceToSend, setVoiceToSend] = useState()
   const [imageToSend, setImageToSend] = useState(null)
   const messagesEndRef = useRef(null)
   const [IsTyping,setIsTyping] = useState(false)
   const [loaded,setLoaded] = useState(false)
   const [isOpenModal, setIsOpenModal] = useState(false)
   const [initialImageSize, setinitialImageSize] = useState({width: 0, height: 0})
   const [initialAspectRatio, setinitialAspectRatio] = useState(null)
   const [croppedImageResult, setCroppedImageResult ] = useState(null)

   
      useEffect(()=>{ 
         const getTimeZone = () => {
         const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
         setTimeZone(timeZone);
      };

      getTimeZone();
      },[])
   
   useEffect(() => {
      if (roomId) {
         apiClient.get(`/api/messages/room?id=${roomId}`, { 
                withCredentials: true
            }).then(res =>{
               setMembers(res.data)
            })
   }   
   if(roomId){
      dispatch({
         type: actionTypes.OPENING_ROOM,
         isRoomOpened: true    
      });
      setHidden(true)
   }
   }, [roomId]);     

   useEffect(()=>{
      if(socket){
         socket.on("newMessage", (newMessage) => {
            setMessages(messages => [...messages, newMessage]);
         });
         socket.on('messagesReadUpdate', (updatedMessages) => {
            setMessages((prevMessages) =>
               prevMessages.map((msg) => {
                 const updatedMessage = updatedMessages.find(updated => updated._id === msg._id);
                 return updatedMessage ? updatedMessage : msg;
               })
             );
         });
         socket.on('typing', () => {
            setIsTyping(true);
            const time = setTimeout(()=>{
               setIsTyping(false);
            },1000)
          });
          return () => {
            socket.off('typing');
            socket.off('newMessage');
            socket.off('messagesReadUpdate');
          };
         }
   },[socket])
    

   useEffect(()=>{
      if(contact){
         const userId = contact._id;
         if(messages.length > 0){
            const unreadMessageIds = messages
               .filter(msg => !msg.readed && msg.senderId === userId) // Сообщения от друга
               .map(msg => msg._id);
            if (unreadMessageIds.length > 0) {
               socket.emit('messagesRead', { messageIds: unreadMessageIds,userId,timeZone });
            }
         }
      }
   },[messages])

useEffect(()=>{
   if(members){
         const filtered = members.participants.filter((users) => String(users) !== String(user._id))
            filtered.map((user)=>{
               apiClient.get(`/api/users/getProfileId?id=${user}`,{ 
                    withCredentials: true,
                }).then(res=>{
                  setContact(res.data)
                }) 
                fetchMsg()
            })
         }
         
      },[members])

      const checkUserOnline = () => {
        socket.emit('checkUserOnline', contact._id, (user) => {
            if(contact.isOnline === user){
               return null
            }
            setContact((prevContact) => ({ ...prevContact, isOnline: user }));
        });
    };
      
      useEffect(() => {
         const interval = setInterval(checkUserOnline, 2000);
         return () => clearInterval(interval);
      }, [contact]);
      
   const fetchMsg = async ()=>{
      if(members){
         const filtered = members.participants.filter((users) => String(users) !== String(user._id))
         if(timeZone){
            await apiClient.get(`/api/messages/room/message?id=${filtered}&tz=${timeZone}`,{
                        withCredentials: true
                     }).then(res =>{
                        setMessages(res.data)
                     }).catch(err => {
                        console.error("Ошибка при получение сообщений")
                     })
         }
      }
   }

   const sendVoiceMessange = (myFile) =>{
      setIsloading(true)
      postToCloudinary(myFile)
         .then( res=>{

            const filtered = members.participants.filter((users) => String(users) !== String(user._id))
            const data = {
               "type":"voice",
               "voice_src": res,
               "tz":timeZone
            }
            apiClient.post(`/api/messages/send?id=${filtered}`,data,{
               withCredentials:true
            }).then(res=>{
               const msg = res.data
               setMessages({...messages,msg})
               setIsloading(false)
            }).catch(error =>{
               console.error("Ошибка при отправке сообщений")
            })

           })
        }

   const sendMessage = (e,File) => {
      e.preventDefault()
      setIsloading(true)
        if(imageToSend){
            postToCloudinary(imageToSend)
            .then( res=>{
               const filtered = members.participants.filter((users) => String(users) !== String(user._id))
               const data = {
                  "type":"image",
                  "image": res,
                  "message": inputRef.current.value,
                  "tz":timeZone
               }
               apiClient.post(`/api/messages/send?id=${filtered}`,data,{
                  withCredentials:true
               }).then(res=>{
                  const msg = res.data
                  setMessages({...messages,msg})
                  setIsloading(false)
               }).catch(error =>{
                  console.error("Ошибка при отправке сообщений")
               })
               inputRef.current.value = ""
               setSrc(null)
               setImageToSend(null)
               setIsloading(false)
               db.collection('rooms')
               .doc(roomId)
               .update({
                  timestamp: firebase.firestore.FieldValue.serverTimestamp()
               })
            })
            .catch( err=> {
               setIsloading(false)
                return
            })
        }else{
         if(text != ""){
               const filtered = members.participants.filter((users) => String(users) !== String(user._id))
               const data = {
                  "type":"message",
                  "message": inputRef.current.value,  
                  "tz":timeZone
               }
               apiClient.post(`/api/messages/send?id=${filtered}&tz=${timeZone}`,data,{
                  withCredentials:true
               }).then(res=>{
                  setIsloading(false)
                  setMessages(messages => [...messages, res.data]);
                  setText("")
                  socket.emit("getUnreadCount",contact._id)
               }).catch(error =>{
                  console.error("Ошибка при отправке сообщений")
               })
               
            setSrc(null)
         }
         }
      }

      const onSelectFile = useCallback((e) => {
         const fileReader = new FileReader();
         fileReader.onloadend = () => {
             setSrc(fileReader.result);
             setImageToSend(fileReader.result);
         };
         fileReader.readAsDataURL(e.target.files[0]);
 
         getInfo(e).then((res) => {
             setinitialImageSize({ width: res.width, height: res.height });
         });
     }, []);


    useEffect(() => {
        setinitialAspectRatio(initialImageSize.width/initialImageSize.height)
    }, [initialImageSize])
  
    useEffect(() => {
      if(roomId){
         if(!loaded){
            setTimeout(()=>{
               messagesEndRef.current.scrollIntoView({behavior: "auto", block:"end"});
            },100)
         }else{
            messagesEndRef.current.scrollIntoView({behavior: "smooth"});
         }
      }
    },[messages]);

    useEffect(()=>{
      setTimeout(()=>{
         setLoaded(true)
      },3000)
    },[])
    
    const changeSrc = () => {
        setSrc(URL.createObjectURL(croppedImageResult))
        setImageToSend(croppedImageResult)
    }
    

    const callbackforModal = () =>{
        changeSrc()
        if (altText.length === 0){
            setAltText(generateAltText(user.displayName))
        }
    }


    const onRecord = async () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          try {
              const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
              onRecording(stream);
          } catch (err) {
              console.error("Error accessing audio stream:", err);
          }
      } else {
         alert('Ваш браузер не поддерживает запись аудио.');
      }
  };
  
  

   const onError = (err)=>{
         console.log("Error",err)
   }

   const onRecording = function(stream){
         const recorder = new MediaRecorder(stream)
         setMediaRecorder(recorder)
         recorder.start()
         let chunks = []

         recorder.onstart = () =>{
            setisRecording(true)
         }

         recorder.onstop = (e) =>{
            setisRecording(false)

            const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
            const myFile = new File([blob], 'voice.ogg')
            chunks = [];
            sendVoiceMessange(myFile)
         } 

         recorder.ondataavailable  = function(e){
            chunks.push(e.data);
         }
   }

   const onstopRecording = async (e)=>{
        mediaRecorder.stop()
   }


    const items = [
        {
            id: 0,
            title:'',
            icon: <CropIcon />,
            item: <CropPhoto 
                    image={src} 
                    setCroppedImageResult ={setCroppedImageResult} 
                    initialAspectRatio    = {initialAspectRatio}
            />
        },
        {
            id: 1,
            title: 'ALT',
            icon: '',
            item: <AddALT image={croppedImageResult} altText={altText} setAltText={setAltText}/>
        }
    ]

    const open = Boolean(anchorEl)
    const id = open ? 'post-popover' : undefined
    const onClickEmoticon = useCallback((event) => setAnchorEl(event.currentTarget),[])
    const handleClose = () => setAnchorEl(null)

    const onEmojiClick = useCallback((event, emojiObject) => {
      if (inputRef.current) {
          let newMessage = inputRef.current.value + emojiObject.emoji;
          inputRef.current.value = newMessage;
      }
     }, []);

    const closeRoom = () => {
         dispatch({
            type: actionTypes.CLOSING_ROOM,
         })  

         history.push('/messages')     
    }

   const viewprofile = ()=>{
      history.push(`/profile/${contact.username}`)
      dispatch({
         type: actionTypes.CLOSING_ROOM,
      })  
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         if(text){
            sendMessage(text)
         }
      }
    } 


    useEffect(()=>{
      if(text != ""){
         socket.emit('typing', contact._id);
      }
    },[text])

    const Container = styled.div`
      width:100%;
      justify-content: center;
      align-items: center;
      display: flex;
      height:100%;
    `


    return (
      <>
            <Modal  open={isOpenModal} 
                    onClose={()=>setIsOpenModal(false)}
                    title="Edit Photo"
                    callback = {callbackforModal}
                    Icon = {ArrowBackOutlinedIcon}
                    ButtonText='Save'
                    >
                    <TabbarMenu items={items}/>
            </Modal>

         <div className="chat">
         <div className="chat__header">
            <div className="chat__backArrow">
               <ArrowBackOutlinedIcon  onClick={closeRoom}/>
            </div>
            <div className="chat__header-ava" style={{cursor:"pointer"}} onClick={()=>viewprofile()}>
               <Avatar src={`https://localhost:8080/avatar/${contact && contact.photoURL}`}/>
               <div>
                  <h2 style={{position:'relative'}}>{contact && contact.fullName}</h2>
                  {IsTyping?
                  <span style={{ position:"relative",left:"15px",fontSize:"11px",color:"var(--text-color)" }}>Печатает...</span>
                  :
                     (contact && contact.isOnline?
                        <span style={{ position:"relative",left:"15px",fontSize:"11px",color:"var(--text-color)" }}>В онлайне</span>
                        :
                        <span style={{ position:"relative",left:"15px",fontSize:"11px",color:"var(--text-color)" }}>Оффлайне</span>
                     )
                  }
               </div>
             </div>
         </div>

                <div className="chat__body">
                               {messages.length > 0?
                                    messages.map(msg=>{
                                       return <MessageItem key={msg.id} roomId={roomId} isMobile={isMobile} timeZone={timeZone} msg={msg} />
                                    })
                                 :
                                    <Container>
                                       <h2>Сообщений пока нет...</h2>
                                    </Container>
                                 }
                   <div ref={messagesEndRef}></div>
                     
                </div>
          <div className="chat__footer">
            
             { src && 
             <div className='chat__footer-ImageBox'>
                <CancelIcon className='cancelIcon' onClick={()=>setSrc(null)}/>
                <img src={src} alt="new test"/>               
                <Button className='editImage' onClick={()=>setIsOpenModal(true)}>Edit</Button>
             </div>
            }

             <StatusInput Icon={InsertPhotoOutlinedIcon}
                         type="file"
                         accept=".jpg, .jpeg, .png"
                         name="image-upload"
                         id="input-image"
                         onChange={onSelectFile}
             />
             <form onSubmit={sendMessage}>
                <input type="text"
                   placeholder='Сообщение'
                   ref={inputRef}
                   onChange={(e)=>setText(e.target.value)}   
                   onKeyDown={handleKeyDown}
                />
                <div className="chat__footer-emowrapper">
                  <SentimentSatisfiedOutlinedIcon aria-describedby={id} type="button" onClick={()=>onClickEmoticon} />
                   <Popover 
                         id={id}
                         open={open}
                         anchorEl={anchorEl}
                         onClose={handleClose}
                         anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                         }}
                         transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                         }}
                         style={{
                            transform: 'translate(-2rem, -1rem)'
                         }}

                   >
                         <Picker onEmojiClick={onEmojiClick} />
                   </Popover>

                </div>
                  {text.length > 0 || src ? (
                     <SendIcon type='submit' className='readyBtn' onClick={(e)=>sendMessage(e)} />
                  ) : (
                     <KeyboardVoiceIcon onClick={(e)=>onRecord(e)} />
                  )}
             </form>

          </div>

      </div>
      </>
    )
   }

export default Chat