import React from "react";
import "./Jobs.css";
import Iconmoder from "../../assets/icons/support_icon.gif";


function Jobs(){
    return(
        <div className="container">
            <section>
                <div className="card__icon">
                    <img src={Iconmoder} />
                </div>
                <div className="title" style={{fontSize:"18px"}}>
                    <h1>Support</h1>
                </div>
            </section>
        </div>
    )
}

export default Jobs