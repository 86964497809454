export const initialState = {
    isRoomOpened: null,
    RoomData: null,
    RoomContact: null
}

export const actionTypes = {
    OPENING_ROOM: 'OPENING_ROOM',
    SET_DATA: "SET_DATA",
    SET_CONTACT:  "SET_CONTACT",
    CLOSING_ROOM: 'CLOSING_ROOM'
}

const isRoomOpenededucers = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_DATA:
            return {
                ...state,
                RoomData: action.payload
            }
        case actionTypes.SET_CONTACT:
            return {
                ...state,
                RoomContact: action.payload
            }

        case actionTypes.OPENING_ROOM:
            return {
                isRoomOpened: true
            }
        
        case actionTypes.CLOSING_ROOM:
            return {
                isRoomOpened: false
            }

        default:
            return state
    }
}

export default isRoomOpenededucers