import React,{useEffect} from "react"
import styled from "styled-components"
import {Avatar} from '@material-ui/core'
import axios from "axios"
import apiClient from "../../utils/api"

const Notification = ({setNotifications,notification,deleteNotification})=>{
    
    const [profile,setProfile] = React.useState({})

    useEffect(() => {
            apiClient.get(`/api/users/getProfileId?id=${notification.senderId}`,{ withCredentials: true
            }).then(res=>{
               setProfile(res.data)
            })
     }, [])

    const Follow = async ()=>{
        await apiClient.get(`/api/users/follow/?username=${profile.username}`,{ 
            withCredentials: true,
        }).then(res=>{
            
        })
        DeleteNotification()
    }

    const DeleteNotification = async ()=>{
        const data = {
            "id":notification._id
        }
        await apiClient.post(`/api/notifications/delete/`,data,{ 
            withCredentials: true,
        }).then(res=>{
            setNotifications(notifications => notifications.filter(item => item._id !== notification._id));
        })
    }

    const Notification = styled.div`
        padding: 15px 10px;
        width:90%;
        height:fit-content;
        top:5px;    
        color:white;
        left:5%;
        display:flex;
        position:relative;
    `

    const Content = styled.div`
        position:relative;
        left:12px
    `

    const ContainerButtons = styled.div`
        width: 250px;
        height: 50px;
        display: flex;
        top: 10px;
        position: relative;
        right: 10px;
    `

    const Button = styled.div`
        align-items: center;
        width: 75px;
        margin-left:10px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        height: 35px;
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
    `

    const NotificationContent = ()=>{
        switch(notification.type){
            case "like":
                return (
                    <Notification>
                        <Avatar src={`http://192.168.0.104:5000/avatar/${profile && profile.photoURL}`} />
                        <Content>
                            <h2 style={{fontSize:"15px"}}>{profile.fullName }</h2>  
                            <span style={{fontSize:"13px"}}>Поставил лайк под постом</span>
                        </Content>
                    </Notification>
                )
            case "comment":
                return(
                    <Notification>
                    <Avatar src={`http://192.168.0.104:5000/avatar/${profile && profile.photoURL}`} />
                        <Content>
                            <h2 style={{fontSize:"15px"}}>{profile.fullName }</h2>  
                            <span style={{fontSize:"13px"}}>Поставил коментарий под постом</span>
                        </Content>
                    </Notification>

                )
            case "follow":
                return(
                    <Notification>
                    <Avatar src={`http://192.168.0.104:5000/avatar/${profile && profile.photoURL}`} />
                    <Content>
                        <h2 style={{fontSize:"15px"}}>{profile.fullName }</h2>  
                        <span style={{fontSize:"13px"}}>Отправил вам заявку в друзья</span>
    
                        <ContainerButtons>
                                <Button onClick={DeleteNotification}>
                                    Отклонить
                                </Button>
                                <Button onClick={Follow} style={{background:"#1677ff"}}>
                                    Добавить
                                </Button>
                        </ContainerButtons>
                    </Content>
                </Notification>
                )
            default:
                
        }
    }
    
    return(
        <NotificationContent />
    )
}

export default Notification