import React, {useState, useEffect} from 'react'
import {useHistory, useParams} from 'react-router'
import Comments from '../Comments/Comments'
import StatusPost from '../StatusPost/StatusPost'
import db from '../../firebase'
import axios from "axios"
import apiClient from '../../utils/api'
import Loader from '../../elements/Loader/Loader'
import MModal from "../../elements/MModal/MModal"
import ChatContextProvider from '../../contexts/ChatContextProvider'

import '../Feed/Feed.css'
import './Status.css'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'

const Status = () => {
    const [comments, setComments] = useState([])
    const [status, setStatus] = useState(null)
    const [statusUsername, setStatusUsername] = useState('')
    const loading= false
    const history = useHistory()
    const {postId} = useParams()

    
    useEffect(() => {
       let mounted = true
      if(postId){
         apiClient.get(`/api/post/?id=${postId}`,{ withCredentials: true
      }).then(res=>{
         setStatus(res.data)
      })
      }
       return () => mounted = false
   }, [postId])
   
   const get = async ()=>{
      if(postId){
         await apiClient.get(`/api/post/?id=${postId}`,{ withCredentials: true
      }).then(res=>{
         setStatus(res.data)
      })
      }
   }
   
   const unlike = async ()=>{
      await apiClient.get(`/api/post/unlike?id=${postId}`,{ withCredentials: true
      }).then(res=>{
         setStatus(res.data)
      })
   }

   const like = async ()=>{
      await apiClient.get(`/api/post/like?id=${postId}`,{ withCredentials: true
      }).then(res=>{
         setStatus(res.data)
      })
   }
   
    useEffect(() => {
      let mounted = true
      if(status){
         apiClient.get(`/api/users/getProfileId?id=${status[0].senderId}`,{ 
                    withCredentials: true,
         }).then(res=>{
            setStatusUsername(res.data.username) 
         }) 
      }

      return () => mounted = false

    }, [])


    return (
        <div className='feed'>
           <div className="status__header">
              <div className="status__backArrow" onClick={()=>history.goBack()}>
                 <ArrowBackOutlinedIcon />
              </div>
              <h2>Запись</h2>          
           </div>
           
           <ChatContextProvider>
               {status && <StatusPost setStatus={setStatus} get={get} like={like} unlike={unlike} status={status} comments={comments}/>} 
           </ChatContextProvider>

           { loading && <div className="feed__loader"><Loader/></div> }

           <Comments comments={comments} statusUsername={statusUsername} />

        </div>
    )
}

export default Status
