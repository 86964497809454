import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './PageTransition.css';

const PageTransition = ({ children }) => {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 914 });

  return (
    <TransitionGroup>
      {isMobile ? (
        <CSSTransition
          key={location.key}
          classNames="page"
          timeout={300}
        >
          {children}
        </CSSTransition>
      ) : (
        children
      )}
    </TransitionGroup>
  );
};

export default PageTransition;
