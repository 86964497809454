import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api";
import { useMediaQuery } from 'react-responsive'
import styled from "styled-components";
import MModal from "../../elements/MModal/MModal"
import { useHistory } from "react-router-dom";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import axios from "axios";

const Balance = ()=>{
    
    const isMobile = useMediaQuery({ maxWidth: 914 })
    const history = useHistory()
    const [open,setOpen] = useState(false)
    const [profile,setProfile] = useState()
    const [selectedId,setSelectedId] = useState(null)

    useEffect(() => {
        document.title = `Баланс`;
    }, []);

    useEffect(() => {
        if(isMobile){
            apiClient.get("/api/users/current").then(res=>{
             setProfile(res.data)
            })
        }else{
            history.goBack()
        }
    }, [])

    const View = styled.div`
        width:100%;
        height:fit-content;
        position:realtive;
        top:52px;
    `

    const Container = styled.div`
        width:100%;
        height:250px;
        align-items:center;
        display:flex;
        position:relative;
    `

    const Content = styled.div`
        flex-direction: column;
        width:100%;
        font-size:12px;
        height:fit-content;
        align-items:center;
        display:flex;
        justify-content: center;
    `    
    const ContinerCard = styled.div`
        width:100%;
        flex-direction: column;
        height:fit-content;
        position:relative;
        display:flex;
    `

    const Title = styled.div`
        width:100%;
        height:50px;
        display:flex;
        position:relative;
        align-items:center;
        color: var(--text-color)
        display:flex;
        justify-content: center;
    `

    const Button = styled.div`
        background-color: var(--twittie-btn-background) !important;
        color: var(--text-color);
        font-weight: 900 !important;
        text-transform: inherit !important;
        border-radius: 0.5rem;
        height: 4rem;
        width: 13rem;
        top:20px;
        position:relative;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 16px;
    `

    const ContainerItems = styled.div`
        width:100%;
        height:500px;
        display:flex;
        flex-direction: column;
        position:relative;
    `

    const Item = styled.div`
        border: 1px solid var(--twittie-background-posterror);
        width:100%;
        display:flex;   
        height:50px;
        position:relative;  
    `

    const ItemTitle = styled.div`
        width: 50%;
        height: 100%;
        align-items: center;
        display: flex;
        left: 30px;
        position: relative;
        font-size: 17px;
        font-weight: 700;
    `

    const ItemContent = styled.div`
        width: 42%;
        justify-content: end;
        font-size: 14px;
        font-weight: 700;
        height: 100%;
        align-items: center;
        display: flex;
    `

    const ModalContainer = styled.div`
        width: 96%;
        left: 2%;
        top: 30px;
        position: relative;
        height: 90%;
        display: flex;
        justify-content: center;
    `

    const TitleContainer = styled.div`
    
    `
    
    const items = [
        {
            id:"1",
            name:"MirNet+",
            privilages:"Временно нету",
            price:"199 pyб",
        },
        {
            id:"2",
            privilages:"Временно нету",
            name:"MirNet Pro",
            price:"499 pуб",
        },
        {
            id:"3",
            privilages:"Временно нету",
            name:"MirNet Ultra",
            price:"1099 pуб",
        }
    ]

    return(
        <>
        <div className='feed' style={{ flex: "none" }}>
            <div className="profile__header">
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <div className="profile__backArrow" onClick={() => history.goBack()}>
                    <ArrowBackOutlinedIcon />
                </div>
                <div className='profile__title'>
                    <div className='profile__title_title'><h2>Баланс</h2>
                </div>
            </div>
        </div>
    </div>
    </div>
    <View>
        <MModal
            ButtonText=""
            open={selectedId? true : false}
            height="70vh"
            animation={true}
            iconend={true}
            closeicon={true}
            onClose={()=>setSelectedId(null)}
        >   

        <ModalContainer>
            <TitleContainer>
                {selectedId !== null && items[selectedId - 1] ? (
                    <h1>{items[selectedId - 1].name}</h1>
                ) : (
                    undefined
                )}
            </TitleContainer>
        </ModalContainer>

        </MModal>
        <Container>
            <Content>
                <h1 style={{marginBottom:"5px"}}>Баланс</h1>
                <h2>{profile && profile.balance} pyб</h2>
                <Button>
                    Попоолнить
                </Button>
            </Content>
        </Container>
        <ContinerCard>
            <Title>
                <h1>Подписки</h1>
            </Title>
            <ContainerItems>
                {items.map((item)=>{
                    return(
                        <Item onClick={()=>setSelectedId(item.id)}>
                            <ItemTitle>
                                <span>
                                    {item.name}
                                </span>
                            </ItemTitle>
                            <ItemContent>
                                <span>{item.price}</span>
                            </ItemContent>
                        </Item>
                    )
                })}
            </ContainerItems>
        </ContinerCard>
    </View>
        </>
    )
}

export default Balance