import React,{useEffect,useState} from 'react'
import {useHistory} from 'react-router'
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import axios from 'axios';

import {useStateValue} from '../../contexts/StateContextProvider'

import Modal from '../../elements/Modal/Modal'

import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'


import '../Feed/Feed.css';
import Notification from '../Sidebar/Notification'

const Notificationsfeed = ({socket}) =>{
    
    const history = useHistory();
    const [notifications,setNotifications] = useState([])
    const isMobile = useMediaQuery({ maxWidth: 914 });

    if(!isMobile){
        history.goBack()
    }

    useEffect(()=>{
        if(isMobile){
                axios.get("http://localhost:8080/api/notifications/",{ withCredentials: true 
                }).then(res=>{
                    setNotifications(res.data)
                })
        }
    },[])

    const deleteNotification = ({id})=>{
        const filtered = notifications.filter((notification) => notification._id !== id)
        setNotifications(filtered)
    }

    useEffect(()=>{
        if(socket){
            socket.on("newNotfication", (NewNotification)=>{
                setNotifications(notifications => ([...notifications, NewNotification]));
            })
        }
    },[socket])

    const Container = styled.div`
        width: 100%;
        height: 100%;
        left:0px;
        background: var(--background);
        border-radius: 15px;
        position: absolute;
        overflow-y:scroll;
        top:53px;
        -ms-overflow-style: none;
        scrollbar-width: none;  
        z-index: 191;
    `

    const Notifications = styled.div`
        width:100%;
        height:80px;
        position:relative;
    `

    const NotificationNotFound = styled.div`
        color:white;
        width: 100%;
        font-size: 13px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    `
    
    return (
        <div>
           <div className='feed' style={{ position: "relative", top: "64px" }}>
                <div className="profile__header">
                    <div className="profile__backArrow" onClick={() => history.goBack()}>
                        <ArrowBackOutlinedIcon />
                    </div>
                    <div className='profile__title'>
                        <div className='profile__title_title'>
                            <h2>Уведомление</h2>
                        </div>
                    </div>
                </div>
            </div>
                <Container>
                    <Notifications>
                        {notifications.length < 1?
                            <NotificationNotFound>
                                <h2>Нету уведомлений</h2>
                            </NotificationNotFound>
                        :
                            notifications.map((notification)=>{
                                return(
                                    <Notification setNotifications={setNotifications} deleteNotification={deleteNotification} notification={notification} />
                                )
                            })
                        }
                    </Notifications>
                </Container>

        </div>
    )
}

export default Notificationsfeed