import React from 'react';
import { Link } from "react-router-dom"
import { Button, Result } from 'antd';

const NotFound = () => (
    <section style={{display:'flex',width:'100%',height:'100%',justifyContent:"center",alignItems:"center"}}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Link to="/"><Button type="primary">Back Home</Button></Link>}
        />
    </section>
);
export default NotFound;