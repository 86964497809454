   import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router'
import TabbarMenu from '../../elements/TabbarMenu/TabbarMenu'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import UserItemFollow from '../UserItem/UserItemFollow'
import {useStateValue} from '../../contexts/StateContextProvider'
import apiClient from '../../utils/api'                                                                                                                                                  // import {useStateValue} from '../../contexts/StateContextProvider'

const ProfileFollow = () => {
   const history = useHistory()
    const [{user}] = useStateValue()
   const {username} = useParams() 
   const [followers, setFollowers] = useState([])
   const [allfollowers, setallFollowers] = useState([])


   const [profile, setProfile] = useState({})
   const [posts, setPosts] = useState([])

   useEffect(() => {
      let mounted = true
      apiClient.get(`/api/users/`,{ 
            withCredentials: true,
        }).then(res=>{
         setallFollowers(res.data)
      })  

      apiClient.get(`/api/users/current`,{ 
            withCredentials: true,
        }).then(res=>{
         setProfile(res.data)
      }) 

      return () => mounted = false
   }, [])


   useEffect(() => {
      if(username !== undefined || username !== null){
         apiClient.get(`/api/users/frieds/?username=${username}`,{ 
            withCredentials: true,
        }).then(res=>{
         setFollowers(res.data)
        })    
      }
   }, [])
   
   useEffect(() => {
      document.title = `Друзья ${profile.fullName}`;
  }, [profile]);

    const items = [
        {
            id: 0,
            title:'Все друзья',
            item: <ul style={{background: "var(--background)", color: "var(--text-color)"}}>
                     {followers.length? 
                        (followers && followers.map(user=>{
                           return  <li key={user._id}> <UserItemFollow display={user}/></li>
                        }))
                     :
                        null
                     }
                  </ul>
        },
        {
         id: 1,
         title:'Поиск друзей',
         item: <ul style={{background: "var(--background)"}}>
                  {allfollowers && allfollowers.map(follow=>{
                     if (user._id === follow._id) {
                        return
                  }
                  else{
                     return <li key={follow._id}> <UserItemFollow display={follow}/></li>
                  }
                  })}
               </ul>
     }
    ]

    return (
        <div className='feed'>
           <div className="profile__header forFollow">
              <div className="profile__backArrow" onClick={()=>history.goBack()}>
                 <ArrowBackOutlinedIcon />
              </div>
              <div className='profile__title'>
                <div className='profile__title_title'><h2>{profile.fullName}</h2>{profile.verified === true? <CheckCircleIcon />: null}</div>        
                <span>{ posts.length>0 ?`${posts.length} tweets`: ''}</span>
              </div>
           </div>

           <TabbarMenu items={items}/>

        </div>
    )
}

export default ProfileFollow
