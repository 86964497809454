import React, {useState, useEffect, useContext} from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import LinearProgress from '@material-ui/core/LinearProgress'
import UserItem from '../UserItem/UserItem'
import UserExist from '../UserItem/UserExist'

import './SearchUser.css'

import db from '../../firebase'

import {Action} from '../../contexts/ChatReducers' 
import {ChatContext} from '../../contexts/ChatContextProvider'
import {useStateValue} from '../../contexts/StateContextProvider'
import axios from 'axios'
import apiClient from '../../utils/api'

const SearchUser = () => {
    const [search, setSearch] = useState('')
    const [existingRooms, setExistingRooms] = useState([])
    const [results, setResults] = useState([])
    const [profile,setProfile] = useState([])
    const [loading, setLoading] = useState(false)
    const [{user}] = useStateValue()
    const {rooms, dispatch} = useContext(ChatContext)

    let memberList = []
    let uniqueMemberList = []

    // const [uniqueMemberLists, setUniqueMemberLists] = useState(uniqueMemberList)

    const onClickResult = (user) => {
        dispatch({
            type: Action.ADD_ROOM,
            room: user
        })
    }

    const onChipDelete = (user) => deleteChip(user)

    const deleteChip = (user) => {
        dispatch({
            type: Action.REMOVE_ROOM,
            id : user._id
        })
    }

    useEffect(() => {
        apiClient.get(`/api/users/current`,{ 
            withCredentials: true,
        }).then(res=>{
            setProfile(res.data)
        }) 
    }, [])

    useEffect(() => {
        apiClient.get(`/api/messages/getConversations`,{ 
            withCredentials: true,
        }).then(res=>{
            setExistingRooms(res.data)
        }) 
    }, [])

    existingRooms.map(room=>room.participants.forEach(participants=> memberList.push(participants)))
    memberList.map(member=>{
        return uniqueMemberList.indexOf(member)===-1 && uniqueMemberList.push(member)
    })

    const isClicked = (user) => rooms.some(item => item._id === user._id)
    
    useEffect(() => {
        apiClient.get(`/api/users/frieds/?username=${profile.username}`,{ 
            withCredentials: true,
        }).then(res=>{
            setResults(res.data)
        })    
    }, [])

    // useEffect(() => {
    //     existingRooms.map(room=>room.members.forEach(member=> memberList.push(member)))
    //     memberList.map(member=>{
    //         return uniqueMemberList.indexOf(member)===-1 && uniqueMemberList.push(member)
    //     })
    //     setUniqueMemberLists(uniqueMemberList)
    // }, [])


    return (
        <div className="searchUser">
           <div className='searchUser__header'>

              {
                  rooms.length>0 && <div className="chips">
                    {
                        rooms.map(resUser => {
                            return  <Chip key={resUser._id}
                            avatar={<Avatar src={`https://localhost:8080/avatar/${resUser && resUser.photoURL}`} />}
                            label={resUser.fullName}
                            onClick={()=>onChipDelete(resUser)}
                            onDelete={()=>onChipDelete(resUser)}
                            />
                        })
                    }
                    </div>
              }

           </div>

           { loading &&  <LinearProgress /> }
           
           <div className="users__result">
           {results?
               results.filter(res=>!uniqueMemberList.includes(res._id)).map(resUser => {
                   return   <UserItem key={resUser._id}
                                      name = {resUser.fullName}
                                      username = {resUser.username}
                                      photourl = {resUser.photoURL}
                                      onClick = {() => onClickResult(resUser)}
                                      clicked = {isClicked(resUser)}            
                   />
               })

            :
            undefined
           }

           </div>        

        </div>
    )
}

export default SearchUser
