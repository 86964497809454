import React,{useState,useEffect} from "react";
import Modal from '../../elements/Modal/Modal'
import db from '../../firebase'
import '../EditProfile/EditProfile.css'
import { TextField } from '@material-ui/core'
import {useStateValue} from '../../contexts/StateContextProvider'
import CloseIcon from '@material-ui/icons/Close'
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined'
import StatusInput from '../StatusInput/StatusInput'

function Edit(){
    const [profile, setProfile] = useState({displayName:'', verified:'', name:'', familyposition:"", followers: [], info:"", username:"", location:'',wallpaper:'', photoURL:''})
    const [{user}] = useStateValue()
    const [isOpenModal, setIsOpenModal] = useState(true)
    const [updatedProfileState, setUpdatedProfileState] = useState({})

    useEffect(() => {
        db.collection('users').doc(user.id).onSnapshot(res=>{
            setProfile(res.data())
        })
    }, [user.id])

    return (
        <div>
            <Modal open={isOpenModal} 
                    onClose={()=>setIsOpenModal(false)}
                    title="Изменить профиль"
                    Icon = {CloseIcon}
                    ButtonText='Сохранить'>
                 <div className='editProfile'>
                <div className="editProfile__theme" style={{backgroundImage: `url(${profile.wallpaper &&  profile.wallpaper})`}}>
                    <div className="editProfile__theme-photoWrapper">
                        {profile.photoURL && <img src={profile.photoURL} alt={`${profile.displayName}`} />}
                        <div className="editProfile__btnWrapper">
                            <StatusInput Icon={AddAPhotoOutlinedIcon}
                                        type="file"
                                        accept="image/*"
                                        name="image-upload"
                                        id="input-wallpaper"
                            />
                        </div>
                    </div>
                    <div className='editProfile__theme-themeActions'>
                        <div>
                            <StatusInput Icon={AddAPhotoOutlinedIcon}
                                        type="file"
                                        accept="image/*"
                                        name="image-upload"
                                        id="input-photo"
                                        
                            />                    
                        </div>
                        <div> </div>
                    </div>
                </div>  

                <form noValidate autoComplete="off">
                    <TextField id="username" label="Никнейм" variant="filled" value={profile.username}/>
                    <TextField id="name" label="Имя" variant="filled" value={profile.name} />
                    <TextField id="name" label="Фамилия" variant="filled" value={profile.fname} />
                    <TextField id="location" label="Город" variant="filled" value={profile.location} />
                    <TextField id="info" label="Краткая информация" variant="filled" value={profile.info} />
                    
                </form>

            </div>
            </Modal>
        </div>
    )
}

export default Edit