import React, {useState,useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {Avatar} from '@material-ui/core'
import './SidebarChatOption.css'
import apiClient from '../../utils/api'
import styled from 'styled-components'
import axios from "axios"
import {useHistory} from 'react-router-dom'
import {useStateValue} from '../../contexts/StateContextProvider'


const SidebarChatOption = ({roomId,socket,participants}) => {
    const [{user}] = useStateValue()
    const history = useHistory()
    const [users,setUser] = useState(participants)
    const [messages, setMessages] = useState([])
    const [contact, setContact] = useState(null)
    const [open,setOpen] = useState(false)
    const [opp, setOpp] = useState('')
    const [count,setCount] = useState(0)
    var holder;
    
    const checkUserOnline = () => {
        if(contact._id){
            socket.emit('checkUserOnline', contact._id, (user) => {
                if(contact.isOnline === user){
                    return null
                }
                setContact((prevContact) => ({ ...prevContact, isOnline: user }));
            });
        }
       };
      
      useEffect(() => {
         const interval = setInterval(checkUserOnline, 3000);
         return () => clearInterval(interval);
      }, [contact]);

    useEffect(()=>{
        if(participants){
            if(participants.length != 0){
                const filtered = users.filter((users) => String(users) !== String(user._id))
                filtered.map((user)=>{
                    apiClient.get(`/api/users/getProfileId?id=${user}`,{ 
                        withCredentials: true,
                    }).then(res=>{
                        setContact(res.data)
                    }) 
                })
            }
        }
    },[])
      
    const Online = styled.div`
        left: 36px;
        bottom: 15px;
        position: relative;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #4bb34b;  
    `

    return (
        <NavLink to={`/messages/${roomId && roomId}`}  className={`sidebarChatOption`} activeClassName='active_chat'>
            <div>
                <Avatar src={`https://localhost:8080/avatar/${contact && contact.photoURL}`}/>
                {contact?
                    (contact.isOnline? <Online></Online> : undefined)
                :
                    undefined
                }
            </div>
            <div className="sidebarChatOption__info">
                <h2> {contact ? contact.fullName: null}
                  <span>{contact ? `@${contact.username}`: null}</span> 
                </h2>
                <p>
                {messages[0] && (messages[0].senderId === user.id? 
                    "Вы:  "
                :
                null
                )}
                {messages[0] && (messages[0].type === "voice"?
                    "Голосовое сообщение"
                :
                null
                )}
                {messages[0] && (messages[0].type === "image"?
                    "Фото"
                :
                null
                )}
                {messages[0] && (messages[0].type === "rep"?
                    "Запись"
                :
                null
                )}
                {messages[0] && (messages[0].type === "messange"?
                    (messages[0].message.length>25?`${messages[0].message.slice(0,24)} ...`:messages[0].message)
                :
                null
                )}
                </p>
                <div>

                </div>
            </div>
        </NavLink>  
    )          
}


export default React.memo(SidebarChatOption)