import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router'
import { Link } from 'react-router-dom'
import {Avatar} from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import axios from "axios"
import CheckIcon from '@material-ui/icons/Check'
import styled from "styled-components";
import apiClient from '../../utils/api'
import Switch from '@material-ui/core/Switch'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import './SidebarAccount.css'
import {useStateValue} from '../../contexts/StateContextProvider'
import db from '../../firebase'

const SidebarAccount = ({setOpen}) => {
    const history = useHistory()
    const [{user}] = useStateValue()
    const [profile, setProfile] = useState([    ])
    
    const selectedtheme = localStorage.getItem("selectedTheme");
    const [theme,settheme] = useState(selectedtheme);

    const [anchorEl, setAnchorEl] = useState(null)
    const onClickExpand= (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const open = Boolean(anchorEl)
    const id = open ? 'post-popover' : undefined

    useEffect(() => {
        apiClient.get("/api/users/current",{ withCredentials: true
      }).then(res=>{
         setProfile(res.data)
      })
    }, [])

    

    const checkban = ()=>{
        if(profile.accountbanned === false){
            return
        }else{
           //s localStorage.setItem("twittie_user", JSON.stringify(profile)) 
           //s history.push('/')
           //s window.location.reload()  
        }
    }
    const updateTheme = (them)=>{
       // db.collection('users').doc(user.id).update({
       //     theme: them,
       // }).then(res => {
       //     return 
       // })
      // // .catch(error => { updateTheme(them)}) 
    }

    useEffect(()=>{
        if (theme !== ""){
            document.querySelector("body").setAttribute("data-theme", theme);
        }else{
            document.querySelector("body").setAttribute("data-theme", "light");
            localStorage.setItem("selectedTheme", "light")
        }
    },[theme])

    const signout = async () => {
            await fetch("https://localhost:8080/api/auth/logout", {
                credentials: 'include',
                method: "POST",
                headers: { "Content-Type": "application/json" },
            }).then(res =>{
                localStorage.clear()
                // window.location.push('/')
                history.push('/')
                window.location.reload()
            });
    }

    const toogletheme = (e) =>{
        if (!e.target.checked){
            settheme("light"); 
            updateTheme("light")
            localStorage.setItem("selectedTheme", "light")
            tooglebody()
        }else {
            settheme("dark");
            updateTheme("dark")
            localStorage.setItem("selectedTheme", "dark")
            tooglebody()
        }
    }

    const tooglebody = ()=>{
        document.querySelector("body").setAttribute("data-theme", theme); 
    }

    const Container = styled.div`
        padding: 1rem;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        transition: 0.1s ease-in-out;
        &:hover{
            background: var(--twittie-hover);
        }
    `

    const Padge = styled.div`
        top: 10px;
        position: relative;
        color: var(--twittie-color-padge);
    `

    const Next = styled.div`
        width: 124px;
        display: flex;
        justify-content: end;
        font-size: 1.3rem;
        color: var(--text-color);
    ` 


       return (
        <>
            <Popover 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                style={{
                    transform:'translate(2rem, -1rem)'
                }}
            >
                <ul className="post__expandList" style={{background:"var(--background)"}}>
        
                    <Container>
                        <Avatar src={`https://localhost:8080/avatar/${profile && profile.photoURL}`}/>
                        <div className="user__details">
                        <h2>{profile &&  profile.fullName}</h2>
                        <span>{`@${profile && profile.username}`}</span>
                        </div>
                        <Padge>
                            <CheckIcon />
                        </Padge>
                    </Container>                              
                    <Link to="/balance">
                        <li>
                            <h3>Баланс  {profile && profile.balance} pyб </h3>
                        </li>
                    </Link>
                    <li onClick={()=>signout()} className='logoutBtn'>
                        <h3>Log out @{profile && profile.username}</h3>
                    </li>
                </ul>
            </Popover>

            <div className='sidebarAccount__wrapper' aria-describedby={id} variant="contained" onClick={onClickExpand }>
                <div className="sidebarAccount__ava">
                    <Avatar src={`https://localhost:8080/avatar/${profile && profile.photoURL}`} />   
                </div>
            </div>
        </>
    )
}

export default SidebarAccount
