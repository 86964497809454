import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useStateValue } from '../../contexts/StateContextProvider';
import apiClient from "../../utils/api";
import Loader from "../../elements/Loader/Loader"
import "./Menu.css";

import MusicNoteIcon from '@material-ui/icons/MusicNote';
import MModal from "../../elements/MModal/MModal";
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import 'react-loading-skeleton/dist/skeleton.css';
import { Avatar } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Menu = ({ isOpenModalinfo, setIsOpenModalinfo }) => {
    const [profile, setProfile] = useState(null);
    const [{ user }] = useStateValue();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(isOpenModalinfo){
            if(!profile){
                apiClient.get(`/api/users/current`, {
                    withCredentials: true,
                }).then(res => {
                    setProfile(res.data);
                    setTimeout(()=>{
                        setLoading(false);                                                                                                                  
                    },1500)
                });
            }
        }
    }, [isOpenModalinfo]);

    return (
            <MModal ButtonText=""
                height="60%"
                open={isOpenModalinfo}
                iconend={true}
                animation={true}
                closeicon={true}
                onClose={() => setIsOpenModalinfo(false)}>
                <div className="modal__children" style={{ position: "relative", top: "50px" }}>
                    <div className="menu_root">
                        {loading ?
                            <div className="skeleton-item">
                                <Loader style={{width:"30%",height:"30%"}}></Loader>
                            </div>
                            :
                            <Link to={"/settings"}>
                            <div style={{ width: "96%", borderRadius: "10px", height: "80px", position: "relative", background: "rgb(14 14 14)", left: "2%", display: "flex", marginBottom: "40px" }}>
                                <div style={{ width: "85px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar src={`https://localhost:8080/avatar/${profile && profile.photoURL}`} style={{ width: "50px", height: "50px" }} />
                                </div>
                                <div style={{ width: "71.5%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", position: 'relative' }}>
                                    <h2>{profile && profile.fullName}</h2>
                                    <ChevronRightIcon style={{ fill: "#ccc", fontSize: "2rem" }} />
                                </div>
                            </div>
                            </Link>
                        }
                        <ul>
                            <Link to={`/frieds/${profile && profile.username}/`}>
                                <li style={{ width: "85px" }} onClick={() => setIsOpenModalinfo(false)}>
                                    <div style={{ width: "100%" }}>
                                        <PeopleIcon />
                                    </div>
                                    <div style={{ width: "50px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                        <h2>Друзья</h2>
                                    </div>
                                </li>
                            </Link>
                            <Link to={`/music`}>
                                <li onClick={() => setIsOpenModalinfo(false)}>
                                    <div style={{ width: "100%" }}>
                                        <MusicNoteIcon />
                                    </div>
                                    <div style={{ width: "50px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                        <h2>Музыка</h2>
                                    </div>
                                </li>
                            </Link>
                            <Link to={`/notifications/`}>
                                <li onClick={() => setIsOpenModalinfo(false)}>
                                    <div style={{ width: "100%" }}>
                                        <NotificationsIcon />
                                    </div>
                                    <div style={{ width: "50px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                        <h2>Уведомление</h2>
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </MModal>
    );
}

export default Menu;
