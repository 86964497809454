export const SET_MODAL_INFO_OPEN = 'SET_MODAL_INFO_OPEN';
export const SET_MODAL_INFO_CLOSE = 'SET_MODAL_INFO_CLOSE';

export const setModalInfoOpen = () => ({
  type: SET_MODAL_INFO_OPEN,
});

export const setModalInfoClose = () => ({
  type: SET_MODAL_INFO_CLOSE,
});
