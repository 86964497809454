import React from 'react'
import './MModal.css'
import { useSwipeable } from 'react-swipeable';
import CloseIcon from '@material-ui/icons/Close';

const MobileModal = ({ open, 
                       dev,
                       height,
                       onClose,
                       callback,
                       animation,
                       iconend,
                       children,
                       closeicon,
                       ButtonText,
}) => {

    const [swipeDelta, setSwipeDelta] = React.useState(0);
    const [isAnimate,setIsAnimate] = React.useState(false)
    const [slowAnimate,setSlowAnimate] = React.useState(false)
    let hideY = 250;
    const [isSwiping, setIsSwiping] = React.useState(false);
    const onClickSave = () => {
        callback()
    }

    React.useEffect(()=>{
        if(open === true){
            setSlowAnimate(true)
            setTimeout(()=>{
                setSlowAnimate(false)
            },300)
        }
    },[open])


    React.useEffect(() => {
        if (swipeDelta > hideY) {
            setSwipeDelta(700)
            setTimeout(()=>{
                setSwipeDelta(0)
                onClose();
            },500)
        }
    }, [swipeDelta, onClose]);

    const handlers = useSwipeable({
        onSwiping: (eventData) => {
            if(animation){
                if (eventData.dir === 'Down') {
                    setSwipeDelta(eventData.absY);
                }
            }
            return false
        },
        onSwipedDown: () => {
            if(animation){
                if (swipeDelta > hideY) {
                    setSwipeDelta(700);
                    setIsAnimate(true)
                    setTimeout(()=>{
                        setSwipeDelta(0)
                        onClose();
                    },500)
                } else {
                    setIsAnimate(true)
                    setSwipeDelta(0);
                    setIsSwiping(false);
                    setTimeout(()=>{
                        setIsAnimate(false)
                    },200)
                }
            }
        },
        onSwiped: () => {
            if(animation){
                if (swipeDelta <= hideY) {
                    setIsAnimate(true)
                    setSwipeDelta(0);
                    setIsSwiping(false);
                    setTimeout(()=>{
                        setIsAnimate(false)
                    },200)
                }
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
        trackMouse: false,
    });


    return (
        
        <div className={`modal__root ${open? 'active': ''}`}>
        <div  className={`modal ${open? 'active': ''}`}  {...handlers } style={{transition:  `${isAnimate? `200ms`: undefined} ease-in-out` ,transform: `translateY(${swipeDelta}px)`,height: height? `${height}` : "95vh"}}>
            {dev?
                undefined
            :
            <>
            <div className='modal__header' style={{position:"fixed"}}>
                <div className='_header'>
                    {iconend?
                        undefined
                    :
                     (
                        closeicon? <CloseIcon onClick={()=>onClose()} fontSize="large" /> : <h2>Отмена</h2>
                     )   
                    }
                </div>
                <div className='_header'  style={{zIndex:"1",justifyContent: "end", width: "45%"}}>
                   {ButtonText.length>0 && <h2 onClickCapture={()=>onClickSave()} >{ButtonText}</h2> }
                   
                   {iconend? <CloseIcon onClick={()=>onClose()} fontSize="large" /> : undefined}
                </div>
            </div>
            {children}
            </>
            }
        </div>
        </div>
    )
}

export default React.memo(MobileModal)
