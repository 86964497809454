import React,{useState,useEffect} from "react"
import { useHistory, Link } from 'react-router';

import "./ProfileGroups.css"
import apiClient from "../../utils/api"
import Search from '../../elements/Search/Search'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import Widgets from "@material-ui/icons/Widgets";

const ProfileGroups = ({setHidden})=>{
    
    const [profile, setProfile] = useState([]);
    const [search,setSearch] = useState("")
    const history = useHistory();

    const groups = [
        {
            id:"1",
            title:"Blume Russia",
            SubjectMatter:"Социальная сеть",
            groupsName:"blumeofficial",
            verifed:true,
            GroupAvatar:"http://localhost:3000/static/media/logo.52c93fb6.png",
            Subscriptions:"25321",
        },
        {
            id:"2",
            title:"KaliHunt/Russia",
            SubjectMatter:"Другое",
            groupsName:"AnonimLegion",
            verifed:true,
            GroupAvatar:"blob:https://web.telegram.org/f0224430-1aa1-4eac-a620-151d9c0d0c59",
            Subscriptions:"950",
        },
    ]


    useEffect(() => {
        setHidden(false)
        const fetchData = async () => {
            try {
                const [userRes] = await Promise.all([
                    apiClient.get(`/api/users/currrent`, { withCredentials: true })
                ]);
                setProfile(userRes.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const styles = {
        container:{
            width:"1000px",
            height:"600px",
            position:"relative",
            top:"80px",
            left:"15px",
            borderRadius:"15px",
        },
        header:{
            width:"100%",
            display:"flex",
            justifyContent:"space-around",
            height: "70px",  
        },
        button:{
            padding: "10px 20px 15px 20px",
            display: "flex",
            color:"var(--text-color)",
            border:"none",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            background: "var(--twittie-background-input)",
        }
    }

    

    return(
        <>
        
            <div className="profile__header">
                    <div className="profile__backArrow" onClick={() => history.goBack()}>
                        <ArrowBackOutlinedIcon />
                    </div>
                    <div className='profile__title'>
                        <div className='profile__title_title'>
                            <h2>Сообщество</h2>
                        </div>
                    </div>
                </div>
                <div style={styles.container}>
                    <div style={styles.header}>
                        <div style={{justifyContent:"space-around",width:"300px",display:"flex"}}>
                            <div>
                                <button type="submit" style={styles.button}>
                                    Все Сообщества 2
                                </button>
                            </div>
                            <div>
                                <button type="submit" style={styles.button}>
                                    Управление 1
                                </button>
                            </div>
                        </div>
                        <div>123</div>
                    </div>
                </div>
        </>
    )
}

export default ProfileGroups