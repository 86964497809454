import React,{ useEffect,useState } from "react"
import styled from "styled-components"
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';

const ThemeChange = ({open,setOpen})=>{
    const selectedtheme = localStorage.getItem("selectedTheme");
    const [th,settheme] = useState(selectedtheme);

    const [updateTh,setUpdateTheme] = useState("")
    const aTh = document.querySelector("body").getAttribute("data-theme");

    const themes = [
        {
            title:"Светлая",
            background:"white",
            theme:"light"
        },
        {
            title:"Темнаа",
            background:"#141414",
            theme:"dark"
        },
        {
            title:"Ночная",
            background:"black",
            theme:"night"
        },
    ]

    const Container = styled.div`
        width:100%;
        height:100%;
        z-index:101;
        display:flex;
        align-items:center;
        justify-content: center;
        position:fixed;
    `

    const Section = styled.div`
        width: 900px;
        backdrop-filter: blur(8px);
        --webkit-backdrop-filter: blur(8px);
        height: 450px;
         border: 1px solid var(--twittie-background-posterror);
        border-radius: 30px;
    `

    const ContainerCard = styled.div`
        width:90%;
        left: 5%;
        display:flex;
        position: relative;
        top: 40px;
    `

    const Body = styled.div`
        width:220px;
        margin-left:30px;
        height:250px;
        position:relative;
    `

    const Card = styled.div`
        width:220px;
        height:200px;
        border-radius:20px;
        position:relative;
    `

    const Title = styled.div`
        width:100%;
        height:50px;
        position:relative;
        display:flex;
        align-items:center;
        justify-content: center;
    `

    const BottomContainer = styled.div`
        display: flex;
        justify-content: end;
        width: 90%;
        bottom: 0px;
        top:150px;
        height:50px;
        align-items:center;
        left:5%;
        position: relative;
    `   

    const Button = styled.div`
        display: flex;
        padding: 1rem 2rem;
        font-size: 14px;
        border-radius: 10px;
        color: white;
        font-weight: 700;
        cursor: pointer;
    `

    const toogleTheme = (theme)=>{
        document.querySelector("body").setAttribute("data-theme", theme);
        setUpdateTheme(theme)
    }

    const updateTheme = () =>{
        localStorage.setItem("selectedTheme", updateTh)
        setOpen(false)
    }

    const Cancel = ()=>{
        document.querySelector("body").setAttribute("data-theme", th);
        setOpen(false)
    }
    
    return(
        <Container style={{display:`${open? "flex": "none"}`}} >
            <Section>
                <ContainerCard>
                    {themes.map((theme) => (
                        <Body onClick={()=>toogleTheme(theme.theme)}>
                            <Card style={{background:`${theme.background}`, border:`3px solid ${theme.theme === aTh? "#1677ff" : "none"}`}}>
                            </Card>
                            <Title>
                                <h1>{theme.title}</h1>
                            </Title>
                        </Body>                    
                    ))    
                }
                </ContainerCard>
                <BottomContainer>
                    <Button onClick={()=>Cancel()}>
                        <CancelIcon style={{fontSize:"2rem",color:"var(--btn-color-text)"}} />
                    </Button>
                    <Button style={{ background: "var(--twittie-btn-background)"}} onClick={()=>updateTheme()}>
                        <CheckIcon style={{fontSize:"2rem"}} />
                    </Button>
                </BottomContainer>
            </Section>
        </Container>    
    )
}

export default ThemeChange