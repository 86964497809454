import React, { useEffect, useState } from "react"
import axios from "axios";
import { useHistory } from 'react-router';
import styled from "styled-components";

const Dev = ()=>{
    const history = useHistory();
    
    useEffect(() => {
        axios.get("http://176.123.165.123:8080/api/users/current",{ withCredentials: true
      }).then(res=>{
         if(!res.data.developer){
            history.goBack()
         }
      })
    }, [])

    const Container = styled.div`
        
    `


    return (
        <>
            <Container>

            </Container>
        </>
    )
}

export default Dev;