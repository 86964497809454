import React, {useState, useEffect, useContext,useCallback,useMemo} from 'react'
import { useMediaQuery } from 'react-responsive'
import Modal from '../../elements/Modal/Modal'
import Search from '../../elements/Search/Search'
import SearchUser from '../SearchUser/SearchUser'
import SidebarChatOption from '../SidebarChatOption/SidebarChatOption'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import CloseIcon from '@material-ui/icons/Close'
import BottomNav from '../../elements/BottomNav/BottomNav'
import { useLocation } from 'react-router-dom';
import './SidebarChat.css'
import MModal from "../../elements/MModal/MModal"

import apiClient from '../../utils/api'
import {useStateValue} from '../../contexts/StateContextProvider'
import {ChatContext} from '../../contexts/ChatContextProvider'
import {useRoomState} from '../../contexts/IsRoomOpenedContextProvider'

const SidebarChat = ({setWelcome,socket}) => {
    const [{user}] = useStateValue()
    const location = useLocation();
    const [{isRoomOpened}] = useRoomState()
    const {rooms, dispatch} = useContext(ChatContext)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [existingRooms, setExistingRooms] = useState([])
    const [existingRoomsUpdated, setExistingRoomsUpdated] = useState([])
    const [text, setText] = useState('')
    // const isNotFirstSender = e => e=== user.id
    const isMobile = useMediaQuery({ maxWidth: 914 })

    useEffect(() => {
        document.title = `Чаты`;
    }, []);

    useEffect(() => {
        const fetchConversations = async () => {
            try {
                const res = await apiClient.get(`/api/messages/getConversations`, { withCredentials: true });
                setExistingRooms(res.data);
            } catch (error) {
                console.error("Error fetching conversations:", error);
            }
        };
        fetchConversations();
    }, []);

    useEffect(() => {
        setWelcome(location.pathname === "/messages/" || location.pathname === "/messages");
    }, [location, setWelcome]);

    const createNewRoom = useCallback(async () => {
        try {
            if (rooms) {
                const data = { receiverId: rooms[0]._id };
                const res = await apiClient.post(`/api/messages/create`, data, { withCredentials: true });
                setExistingRooms(existingRooms => [...existingRooms, res.data]);
            }
        } catch (error) {
            console.error("Error creating new room:", error);
        }
    }, [rooms]);


    const dynamicSearch = () => existingRooms.filter(room=>room.alias.toLowerCase().includes(text.toLowerCase()))

    //useEffect(()=>{
    //    setExistingRoomsUpdated(existingRooms.sort((a,b) => b.timestamp - a.timestamp))
    //},[existingRooms])

    return (
        <>
        {isMobile? 
            <MModal open={isOpenModal} 
                    onClose={()=>setIsOpenModal(false)}
                    ButtonText='Создать'
                    animation={true}
                    callback = {createNewRoom}>
                <div className='modal__children' style={{position:"relative", top:"50px"}}>
                    <SearchUser />
                </div>
            </MModal>
        :   
            <Modal  open={isOpenModal} 
                    onClose={()=>setIsOpenModal(false)}
                    title="Новое сообщение"
                    callback = {createNewRoom}
                    Icon = {CloseIcon}
                    ButtonText='Создать'
                    >
                    <SearchUser />
            </Modal>        
        }

            <>
                <div className={`sidebarChat ${(isRoomOpened && isMobile) && 'noned'}`}>
                    <div className="sidebarChat__header">
                        <h2>Сообщений</h2> 
                        <div className="sidebarChat__addMessage">
                           <MailOutlineIcon onClick={()=>setIsOpenModal(true)}/>
                        </div>         
                    </div>

                    <Search value={text} 
                            onChange = {(e)=>setText(e.target.value)}
                            onClick={()=>setText('')}
                            placeholder={"Поиск чата..."}
                    />            

                    <div className="sidebarChat__chats">
                    {
                        existingRooms
                        .sort((a,b) => b.timestamp - a.timestamp)
                        .map(room=>(
                          <SidebarChatOption key={room._id}
                            socket={socket}
                            participants = {room.participants}
                            isMobile = {isMobile}
                            roomId = {room._id}
                          />
                        ))
                    }
                    </div>            
                    <BottomNav />
                </div>
            </>
        </>
    )
}

export default SidebarChat
