import React, { useEffect, useState, useCallback,useMemo, useRef } from "react";
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import apiClient from "../../utils/api";
import SearchWidget from '../../elements/SearchWidget/SearchWidget';

import { tracks } from "./data";
import axios from "axios";
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@material-ui/icons/Close'
import Modal from "../../elements/Modal/Modal";
import BuildIcon from '@material-ui/icons/Build';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AddIcon from '@mui/icons-material/Add';

import VolumeUp from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider';
import PauseIcon from '@mui/icons-material/Pause';
import styled from "styled-components";

import "./Music.css";
import Track from "./track";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import '../Feed/Feed.css';

const Music = () => {
  const [value, setValue] = React.useState(30);
  const [user,setProfile] = useState(null)
  const [volume, setVolume] = React.useState(60);
  const clickRef = useRef();
  const [trackProgress, setTrackProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [show, setShow] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 914 });
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [openplayer, setopenplayer] = useState(false);
  const intervalRef = useRef();
  const audioRef = useRef();
  const history = useHistory();
  const [open,setOpen] = useState(false)
  const [text, setText] = useState("");
  const [tracks, setTracks] = useState([]);
  const [currentSong, setCurrentSong] = useState();
  
  const [cover, setCover] = useState(null);
  const [audio, setAudio] = useState(null);
  const [name,setName] = useState(null)
  const [artist,setArtist] = useState(null)

  useEffect(() => {
    document.title = `Музыка`;
}, []);


  const play = useCallback(() => {
    if (audioRef.current) {
      setIsPlaying(true);
      audioRef.current.play();
      startTimer();
    }
  }, []);
  
  const pause = useCallback(() => {
    if (audioRef.current) {
      setIsPlaying(false);
      clearInterval(intervalRef.current);
      audioRef.current.pause();
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const [TrackData,UserData] = await Promise.all([
          apiClient.get("/api/music/", { withCredentials: true }),
          apiClient.get("/api/users/current",{ withCredentials: true})
        ])
        setProfile(UserData.data)
        setTracks(TrackData.data);
        if (TrackData.data.length > 0) {
          setCurrentSong(TrackData.data[0]);
        }
      } catch (error) {
        console.error("Error fetching tracks:", error);
      }
    };

    fetchTracks();
  }, []);

  useEffect(() => {
    if (audioRef.current && currentSong) {
      audioRef.current.src = `https://localhost:8080/audio/${currentSong.audio}`;
      audioRef.current.load();
      setIsReady(true);
    }
  }, [currentSong]);

  useEffect(() => {
    if (isReady && isPlaying) {
      play();
    }
  }, [isReady, isPlaying, play]);

  const prevPlay = useCallback(() => {
    if (tracks.length > 0) {
      const index = tracks.findIndex(x => x.name === currentSong.name);
      const newIndex = (index === 0) ? tracks.length - 1 : index - 1;
      setCurrentSong(tracks[newIndex]);
      setTrackProgress(0);
      pause()
      setTimeout(() => {
        play();
      }, 200);
    }
  }, [currentSong, tracks]);

  const nextPlay = useCallback(() => {
    if (tracks.length > 0) {
      const index = tracks.findIndex(x => x.name === currentSong.name);
      const newIndex = (index === tracks.length - 1) ? 0 : index + 1;
      setCurrentSong(tracks[newIndex]);
      setTrackProgress(0);
      pause()
      setTimeout(() => {
        play();
      }, 200);
    }
  }, [currentSong, tracks]);

  const startTimer = () => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current && audioRef.current.ended) {
        nextPlay();
      } else if (audioRef.current) {
        setTrackProgress(audioRef.current.currentTime);
        setDuration(audioRef.current.duration);
      }
    }, 500);
  };

  const onScrub = (value) => {
    clearInterval(intervalRef.current);
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setTrackProgress(value);
    }
  };

  const onScrubEnd = useCallback(() => {
    if (!isPlaying) {
      setIsPlaying(true);
      alert("ScrubEnded")
    }
    startTimer();
  },[isPlaying]);

  const playSong = (index) => {
    pause()
    setCurrentSong(tracks[index]);
    setShow(true);
    setTimeout(() => {
      play();
    }, 200);
  } ;


  const handleCoverChange = useCallback((e) => {
    setCover(e.target.files[0]);
  },[]);

  const handleAudioChange = (e) => {
    setAudio(e.target.files[0]);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('music', audio);

    try {
      const response = await axios.post('http://localhost:8080/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const data = {
        "name": name,
        "cover":cover,
        "artist":artist,
        "audio":response.data.files.music,
      }

      axios.post("http://localhost:8080/api/music/upload",data, { withCredentials: true }).then(res=>{
        setTracks(tracks => [...tracks,res.data])
      })

      setAudio(null)
      setCover(null)
      setName(false)
      setArtist(false)

      setOpen(false)
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  
  const DevOption = ()=>{
    if (user && user.developer) {
      return (
        <div className="dev_icon">
          <AddIcon onClick={() => setOpen(true)} style={{ cursor: "pointer" }} />
        </div>
      );
    }
  }

  const TrackList = useMemo(() => {
    return (
      <section className="container__tracks">
        {
        tracks.map((track, index) => (
        <Track
          track={track}
          playSong={playSong}
          key={index}
          index={index}
        />
        ))
      }
      </section> 
    )
  }, [tracks]);

  const SliderVolume = useMemo(() => {
    return (
      <Slider
          size="small"
          onChange={(e) => setVolume(e.target.value)}
          defaultValue={70}
          aria-label="Small"
          valueLabelDisplay="auto" /> 
    )
  }, [volume]);

  const Control = useMemo(() => {
    return (
      <>
        <SkipPreviousIcon style={{ fontSize: "30px" }} onClick={() => prevPlay()} />
        {isPlaying ?
          <PauseIcon sx={{ fontSize: 30 }} onClickCapture={() => pause()} />
          :
          <PlayArrowIcon sx={{ fontSize: 30 }} onClickCapture={() => play()} />
        }
        <SkipNextIcon sx={{ fontSize: 30 }} onClick={() => nextPlay()} />
      </>
    )
  }, [isPlaying]);


  const hide = ()=>{
    setIsPlaying(false)
    setShow(false)
  }
  

  return (
    <>
    <Modal
      open={open} 
      onClose={()=>setOpen(false)}
      title="Добавление музыки"
      callback = {null}
      Icon = {CloseIcon}
      ButtonText='Save'
    >
      <form onSubmit={handleSubmit}>
          <div>
          <input type="text" onChange={(e)=>setName(e.target.value)} placeholder="Название"/>
          </div>
          <div>
            <input type="text" onChange={(e)=>setCover(e.target.value)} placeholder="Сыллка" />
          </div>
        <div>
          <input type="text" onChange={(e)=>setArtist(e.target.value)} placeholder="Артист" />
        </div>
        <div>
          <input type="file" accept="audio/*" onChange={handleAudioChange} required />
        </div>
        <button type="submit">Upload</button>
      </form>

    </Modal>
      <audio ref={audioRef} onTimeUpdate={() => setTrackProgress(audioRef.current.currentTime)} />
      <div className='feed' style={{ flex: "none" }}>
        <div className="profile__header">
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div className="profile__backArrow" onClick={() => history.goBack()}>
              <ArrowBackOutlinedIcon />
            </div>
            <div className='profile__title'>
              <div className='profile__title_title'><h2>Музыка</h2>
              </div>
            </div>
          </div>
          {DevOption()}
        </div>
      </div>
      
          {TrackList}

      {isMobile ?
        <div className={`player_mobile ${show ? "active" : ''}`}>
          <div className="player_mobile_control">
            {isPlaying ?
              <PauseIcon sx={{ fontSize: 25 }} onClickCapture={() => pause()} />
              :
              <PlayArrowIcon sx={{ fontSize: 25 }} onClickCapture={() => play()} />
            }
          </div>
          <div className="player_mobile_artist" onClick={() => setopenplayer(true)}>
            {currentSong?
              <>
                <span style={{ fontSize: "12px" }} className="player_mobile_artist_name">{currentSong.name}</span>
                <span style={{ color: "rgb(170,170,170)" }}>{currentSong.artist}</span>
              </>
              :
              undefined
            }
          </div>
          <div className="next_skipicon">
            {isPlaying?  
              <SkipNextIcon sx={{ fontSize: 25 }} onClick={() => nextPlay()} />
            :
              <CloseIcon sx={{ fontSize: 30 }} onClick={() => hide()} />
            }
          </div>
        </div>

        :

        <section className={`player_section ${show ? "active" : ''}`}>
          <div className="player">
            <div className="player_artist_cover">
              {currentSong ? <img src={currentSong.cover} alt={currentSong.name} /> : undefined}
            </div>
            <div className="player_range">
              <div className="player_artist">
                {currentSong ?
                  <>
                    <span className="artist_name">{currentSong.name}</span>
                    <span style={{ color: "rgb(170,170,170)" }}>{currentSong.artist}</span>
                  </>
                  :
                  null
                }
              </div>
            </div>
            <div className="progress_range">
            <Slider
              size="small"
              step="1"
              onMouseUp={onScrubEnd}
              onKeyUp={onScrubEnd}
              onChange={(e) => onScrub(Number(e.target.value))}
              max={duration}
              value={trackProgress.toString()}
              valueLabelDisplay="none" /> 
            </div>
            
            <div className="player_controls">
                {Control}
            </div>
            <div className="player_volue">
              <VolumeUp fontSize="large" className="icon_volue" />
              {SliderVolume}
            </div>
          </div>
        </section>
      }

      <div className={`player_box_mobile ${openplayer ? "active" : ''}`}>
        <div className="close_header" onClick={() => setopenplayer(false)}>
          <div className="br_close"></div>
        </div>
        <div style={{position:"relative",top:"45px"}}>
          <div className="artist_cover">
            {currentSong ?
              <img src={currentSong.cover} alt={currentSong.name} /> : undefined}
          </div>
          <div className="box_range">
            <input
              type="range"
              step="1"
              onMouseUp={onScrubEnd}
              onKeyUp={onScrubEnd}
              onChange={(e) => onScrub(Number(e.target.value))}
              max={duration}
              value={trackProgress}
            />
          </div>
          <div className="artist_info">
            {currentSong ?
              <>
                <span className="artist_name">{currentSong.name}</span>
                <span style={{ color: "rgb(170,170,170)", fontSize: "12px" }}>{currentSong.artist}</span>
              </>
              :
              undefined
            }
          </div>
          <div className="box_controls">
            <SkipPreviousIcon style={{ fontSize: "50px" }} onClick={() => prevPlay()} />
            {isPlaying ?
              <PauseIcon style={{ fontSize: "50px" }} onClickCapture={() => pause()} />
              :
              <PlayArrowIcon style={{ fontSize: "50px" }} onClickCapture={() => play()} />
            }
            <SkipNextIcon style={{ fontSize: "50px" }} onClick={() => nextPlay()} />
          </div>

        </div>
      </div>

    </>
  );
};

export default Music;