import React, {useState, useEffect} from 'react'
import firebase from 'firebase'
import './Login.css'
import logo from "../Sidebar/logo.png"
import { useMediaQuery } from 'react-responsive'
import apiClient from '../../utils/api'
import validate from '../../helpers/inputValidator'
import db from '../../firebase'
import styled from "styled-components"
import ErrorIcon from '@mui/icons-material/Error';
import MModal from '../../elements/MModal/MModal'
import {actionTypes} from '../../contexts/StateReducers'
import {useStateValue} from '../../contexts/StateContextProvider'
import { Button, Checkbox, Form, Grid, Input, theme, message, Spin, Typography } from "antd";

import { LockOutlined, MailOutlined,LoadingOutlined  } from "@ant-design/icons";    

const Login = () => {
    const { useToken } = theme;
    const { useBreakpoint } = Grid;
    const { Text, Title, Link } = Typography;
    const { token } = useToken();
    const dev = true
    const screens = useBreakpoint();
    const [modalOpen,setModalOpen] = useState(false)
    const [auth, setAuth] = useState("login")         // a state to change auth mode

    const [signup, setSignup] = useState({
        fname: '',
        name: '',
        username: '',
        phone:"",
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSignup(prevSignup => ({
            ...prevSignup,
            [name]: value
        }));
    };

    const [{user},dispatch] = useStateValue()
    const [email, setPhone] = useState(null)
    const [password, setPassword] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    
    const [signupState, setSignupState] = useState(signup)

    const isMobile = useMediaQuery({ maxWidth: 914 })
    const [open,setOpen] = React.useState(false)
    const [isSigning, setIsSigning] = useState(false)

    const cleanupState = () => {
        setIsSigning(false)
        setError('')
        setLoading(false)
    }

    const toggleAuth = () => {
        setAuth(auth=>!auth)
        cleanupState()
        // console.log(!auth?'changed to signupPage':'changed to loginPage')
    }

    const auths = (user) => {
        try {
            localStorage.setItem('twittie_user', JSON.stringify(user))
            localStorage.setItem("selectedTheme", user.theme)

            dispatch({
                type: actionTypes.SET_USER,
                user : JSON.parse(localStorage.getItem('twittie_user'))
            })
            
            setLoading(false)          
        } catch (error) {
            message.error("Произошла ошибка")
            setLoading(false)
            return
        }
    }

    const onSubmitLogin = (e) => {  
        e.preventDefault()
        setLoading(true)
        db.collection('users')
            .where('email', '==', email)
            .where('password','==', password)
            .limit(1)
            .get()
            .then(snapshot=>{
                if(!snapshot.docs.length){
                   // setError('Неверные учетные данные')
                return
                } else {
                    return {id: snapshot.docs[0].id, ...snapshot.docs[0].data()}
                }
            })
            .then(res=>{
                if(res){
                    auths(res)
                } else {
                    setError('Произошла ошибка, попробуйте еще раз')
                    setLoading(false)
                }
            })
            .catch(error=>{
                setLoading(false)
                setError('Произошла ошибка, попробуйте еще раз')
                return
            }
        )

    }

    const preSignup = (e) => {
       // setError('Регистрация отключена разработчиком')
        e.preventDefault()
        setIsSigning(true)
        setError(validate(signupState))
    }

    // console.log(`currently in ${auth?'signupPage':'loginPage'} and ${isSigning?'isSigning':'not isSigning'}`

    const styles = {
        container: {
          margin: "0 auto",
          padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
          width: "380px"
        },
        footer: {
          marginTop: token.marginLG,
          textAlign: "center",
          width: "100%",
        },
        forgotPassword: {
            float: "right"
        },
        header: {
            marginBottom: token.marginXL
        },
        section: {
            alignItems: "center",
            backgroundColor: "black",
            display: "flex",
            height: screens.sm ? "100vh" : "auto",
            padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
        },
        text: {
          position:"relative",
          height:"50px",
          color: "white"
        },
        title: {
          display:"flex",
          justifyContent:"center",
          position:"relative",
          top:"10px",
          color:"white",
          fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        },
        logo:{ 
          justifyContent:"center",
          display:"flex",
          width:"100%"
        }
      };

    const ModalContainer = styled.div`
        width: 100%;
        position: relative;
        border-radius:15px;
        color:white;
        background:black;
        height: 100%;
        display: flex;
        justify-content: center;
    `


    const onFinish = async (values)=>{
        if(dev){
            return setModalOpen(true)
        }
        if(auth === "login"){
            setLoading(true)
            const data = {
                "email":email,
                "password":password 
            }
            await apiClient.post("/api/auth/login", data, {
                withCredentials: true, // Ensure cookies are included
            }).then((result)=>{
                const res = result.data
                if(res.error){
                    setLoading(false)
                    return message.error(res.error)
                }
                auths(result.data)
                setLoading(false)

            }).catch(error =>{
                setLoading(false)
            });

            }else if(auth === "register"){
                const {name, fname, username, email, password } = signup
                const fullName = name + " " + fname
                const data = JSON.stringify({ name, fname, fullName, username, email, password })
                    setLoading(true)
                    return apiClient.post("/api/auth/signup", data, {
                        headers: { "Content-Type": "application/json" },
                    })
                    .then((result) => {
                        setAuth("login")
                    })
                    .catch((err) => {
                        const resp = err.response.data
                        setLoading(false)
                        return message.error(resp.error)
                    });
            }else if(auth === "reset"){
                setLoading(true)
                message.error("В разработке")
                setTimeout(()=>{
                    setLoading(false)
                },500)
            }else{
                return
            }
    }

    const handlesetAuth = (e,param)=>{
        e.preventDefault()
        setAuth(param)
    }

    const handleAuth = ()=>{
        switch(auth){
            case "login":
                return(
                    <div style={styles.container}>
                        <div style={styles.header}>
                            <div style={styles.logo}>
                                <img src={logo} style={{width:"90px",position:"relative"}} />
                            </div>
                            <Title style={styles.title}>Вход в Blume</Title>
                            </div>
                            <Form>
                                <input className='input-control' onChange={(e)=>setPhone(e.target.value)} placeholder='Number' type="text" />
                                <input className='input-control' onChange={(e)=>setPassword(e.target.value)} placeholder='Password' type="password" />
                                <Form.Item style={{ marginBottom: "0px" }}>
                                <Button block="true" type="primary"  htmlType="submit" onClick={()=>setModalOpen(true)} >
                                {loading? <Spin indicator={ <LoadingOutlined style={{ fontSize: 20,color:"white" }} spin /> } /> : "Войти"}
                                </Button>
                                <div style={styles.footer}>
                                    <Text style={styles.text}>У вас нету аккаунта?</Text>{" "}
                                    <Link style={{position:"relative",left:"10px"}} onClick={(e)=>handlesetAuth(e,"register")}>Создайте аккаунт</Link>
                                </div>
                                </Form.Item>
                            </Form>
                            </div>
                )
            case "register":
                return (
                    <div style={styles.container}>
                    <div style={styles.header}>
                        <div style={styles.logo}>
                            <img src={logo} style={{width:"90px",position:"relative"}} />
                        </div>
                        <Title style={styles.title}>Создание аккаунта</Title>
                        <div style={{width:"100%", textAlign:"center"}}>
                            <Text style={styles.text}>
                                Для того,чтобы создать аккаунт. Введите ниже данные
                            </Text>
                        </div>
                    </div>
                    <Form
                        name="normal_login"
                        initialValues={{
                        remember: true,
                        }}
                        layout="vertical"
                        requiredMark="optional"
                        onFinish={onFinish}
                    >
                       <input className='input-control' onChange={handleChange} name='phone' placeholder='Number' type="text" />
                       <input className='input-control' onChange={handleChange} name='name' placeholder='Name' type="text" />
                       <input className='input-control' onChange={handleChange} name='username' placeholder='Username' type="text" />
                       <input className='input-control' onChange={handleChange} name='fname' placeholder='Last name' type="text" />
                       <input className='input-control' onChange={handleChange} name='email' placeholder='email' type="text" />
                       <input className='input-control' onChange={handleChange} name='password' placeholder='password' type="password" />
                        <Form.Item style={{ marginBottom: "0px" }}>
                        <Button block="true" type="primary" htmlType="submit" >
                           {loading? <Spin indicator={ <LoadingOutlined style={{ fontSize: 20,color:"white" }} spin /> } /> : "Создать аккаунт"}
                        </Button>
                        <div style={styles.footer}>
                            <Text style={styles.text}>Есть аккаунт?</Text>{" "}
                            <Link onClick={(e)=>handlesetAuth(e,"login")}>Войдите</Link>
                        </div>
                        </Form.Item>
                    </Form>
                    </div>
                )
            case "reset":
                return(
                    <div style={styles.container}>
                        <div style={styles.header}>
                            <div style={styles.logo}>
                                <img src={logo} style={{width:"90px "}} />
                            </div>
                            <Title style={styles.title}>Востановление пароля</Title>
                            <div style={{width:"100%", textAlign:"center"}}>
                            </div>
                            </div>
                            <Form
                                name="normal_login"
                                initialValues={{
                                remember: true,
                                }}
                                layout="vertical"
                                requiredMark="optional"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                name="email"
                                rules={[
                                    {
                                    type: "email",
                                    required: true,
                                    message: "Введите Email!",
                                    },
                                ]}
                                >
                                <Input
                                    prefix={<MailOutlined />}
                                    placeholder="Email"
                                />
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "0px" }}>
                                    <Button block="true" type="primary" htmlType="submit">
                                         {loading? <Spin indicator={ <LoadingOutlined style={{ fontSize: 20,color:"white" }} spin /> } /> : "Отправить код"}
                                    </Button>
                                    <div style={styles.footer}>
                                        <Text style={styles.text}>Вспомнили пароль?</Text>{" "}
                                        <Link onClick={(e)=>handlesetAuth(e,"login")}>Войдите</Link>
                                    </div>
                                </Form.Item>
                            </Form>
                            </div>
                )
        }
    }


    return (
        <>
        <section style={styles.section}>
            { handleAuth() }    
        </section>
        {isMobile?
            <MModal
                ButtonText=""
                open={modalOpen}
                height="40vh"
                animation={false}
                iconend={true}
                closeicon={true}
                onClose={()=>setModalOpen(null)}
            >   
                <ModalContainer>
                    <div style={{width:"90%"}}>
                        <div style={{height:"50%",alignItems:"center",display:'flex',flexDirection:"column",justifyContent:"center"}}>
                            <h1>
                                Сервис временно недоступен
                            </h1>
                        </div>
                        <div style={{height:"50%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <Button block="true" type="primary" htmlType="submit" style={{height:"50px",fontSize:"18px",fontWeight:'600',width:"100%"}} onClick={()=>setModalOpen(false)}>
                                Понял
                            </Button>
                        </div>
                    </div>
                </ModalContainer>
            </MModal>
            :
            undefined
        }
        </>
    )
}

export default Login
