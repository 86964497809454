import React,{useEffect} from 'react'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import styled from "styled-components";
import './UserItem.css'
  

const UserItem = ({name, username, photourl, clicked, onClick}) => {

    const User = styled.div`
        background: var(--background);
        padding: 1rem;
        display: flex;
        flex-direction: row;
        cursor: pointer;

    &:hover{
        background: var(--twittie-hover);
    }
    `

    return (
        <User className="user__item" onClick={!clicked ?onClick:undefined}>
            <Avatar src={`http://localhost:8080/avatar/${photourl && photourl}`}/>
            <div className="user__details">
               <h2>{name}</h2>
               <span>@{username}</span>
            </div>
            {
                clicked && <CheckIcon />
            }
        </User>
    )
}

export default UserItem
