exports.tracks = [
  {
      name: "Limbo 2020 (Jack Mazzoni Remix)",
      cover: "https://chillhop.com/wp-content/uploads/2020/09/c209a7df7b9bc133dfff73ce86ebc3c57c2b73dd-1024x1024.jpg",
      artist: "Daddy Yankee",
      audio: "http://localhost:5000/audio/123.mp3"
  },
  {
      name: "WA$$TED x UWU",
      cover:"https://e-cdns-images.dzcdn.net/images/cover/8fe6eb3fd1af96d5de55934755f0d9c1/250x250.jpg",
      artist: "KYSSO DYSSO",
      audio: "http://localhost:5000/audio/MOROZOFF-Big%20Bucks%20-New%20eurodance%202021.mp3"
  }
]