import React, {useState, useEffect,useContext} from 'react'
import {useParams} from 'react-router'
import {useHistory,Link} from 'react-router-dom'

import Popover from '@material-ui/core/Popover'
import FooterIcon from '../Post/FooterIcon'
import Like from '../Post/Like'
import Reply from '../Reply/Reply'
import Modal from '../../elements/Modal/Modal'

import {Avatar} from '@material-ui/core'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import RepeatIcon from '@material-ui/icons/Repeat'
import PublishIcon from '@material-ui/icons/Publish'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import BarChartIcon from '@material-ui/icons/BarChart'
import CodeIcon from '@material-ui/icons/Code'
import PlaceIcon from '@material-ui/icons/Place'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import BlockIcon from '@material-ui/icons/Block'
import PostAddIcon from '@material-ui/icons/PostAdd'
import firebase from "firebase"
import RepostWidget from "../RepostWidget/RepostWidget"
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CloseIcon from '@material-ui/icons/Close'
import apiClient from '../../utils/api'
import {ChatContext} from '../../contexts/ChatContextProvider'
import MModal from "../../elements/MModal/MModal"
import axios from "axios"

import {useStateValue} from '../../contexts/StateContextProvider'
import util from '../../helpers/timeDifference'
import {convertTimestampToLocaleString} from '../../helpers/convertTimestampToLocaleString'
import db from '../../firebase'
import Rep from "../Post/Rep"
import {Action} from '../../contexts/ChatReducers' 
import "./StatusPost.css"

const StatusPost = ({status,like,unlike,get,comments}) => {
   const {postId} = useParams()
   const {rooms, dispatch} = useContext(ChatContext)
   const [{user}] = useStateValue()
   const [profile, setProfile] = useState({id:'',fullName:'', photoURL: '', verified: false, username: '', followers: [], following: []})
   const {
      text,
      image,
      timestamp,
      reposted,
      senderId,
      likes
   } = status

   const date = convertTimestampToLocaleString(timestamp)
   const history = useHistory()

   const [isOpenModal, setIsOpenModal] = useState(false)
   const [isOpenModalRepost, setIsOpenModalRepost] = useState(false)
   const [anchorEl, setAnchorEl] = useState(null)
   const [isMobile,setIsMobile] = useState(true)
   const onClickExpand= (event) => setAnchorEl(event.currentTarget)
   const handleClose = () => setAnchorEl(null)
   const [isLiked,setIsLiked] = useState(false)
   const open = Boolean(anchorEl)
   const id = open ? 'post-popover' : undefined
   const [isFollowing, setIsFollowing] = useState(false)

   useEffect(() => {
      let mounted = true
      if(mounted || profile){
         apiClient.get(`/api/users/getProfileId?id=${senderId}`,{ 
            withCredentials: true,
         }).then(res=>{
            setProfile(res.data) 
         }) 
      }

      return () => mounted = false
   }, [])

   const deletePost = async ()=>{
      await apiClient.post(`/api/post/delete?id=${postId}`,{ withCredentials: true
      }).then(res=>{
         history.goBack()
      })
   }

   const subscriptre = async ()=>{
      await apiClient.get(`/api/users/subscription/?username=${profile.username}`,{ 
          withCredentials: true,
      }).then(res=>{
          setProfile(res.data)
      })
  }

  const unsubscriptre = async ()=>{
      await apiClient.get(`/api/users/unsubscription/?username=${profile.username}`,{ 
          withCredentials: true,
      }).then(res=>{
          setProfile(res.data)
      })
  } 

   useEffect(()=>{
      setIsLiked(likes.includes(user._id))
   },[likes])
    
   const handleResize = () => {
      if (window.innerWidth < 720) {
        setTimeout(()=>{
            setIsMobile(true)
        },2000)
      } else {
          setIsMobile(false)
      }
    }

   useEffect(() => {
      handleResize()
   })

   useEffect(() => {
      if(profile){
         setIsFollowing(profile.followers.includes(user._id))
      }
   }, [profile])

   const callbackForModal = () => {}
   const callbackrep = ()=> {
      if (rooms){
         rooms.forEach(room => {
            const roomId = room.id
               db.collection('rooms')
                  .doc(roomId)
                  .collection('messages')
                  .add({
                     postId: postId,
                     senderId: user.id,
                     type: "rep",
                     timestamp: firebase.firestore.FieldValue.serverTimestamp()
                   })
              
              dispatch({
               type : Action.REMOVE_ROOM,
               id   : room.id
             })    
         })
         db.collection("posts")
         .doc(postId)
         .update({
            reposted: firebase.firestore.FieldValue.increment(+1)
         })
     }
   }

   const deleteMyPost = ()=> {
      deletePost(postId)
      history.push('/')
   }

   const setIsOpenParentModal = state => setIsOpenModal(state)

   return (
   <>
      {isMobile?
      <MModal  
         open={isOpenModal} 
         onClose={()=>setIsOpenModal(false)}
         callback = {callbackForModal}
         ButtonText=''
      >
         <div className='modal__children' style={{position:"relative", top:"50px"}}>
          
         </div>
      </MModal>
      
      :
      <Modal  
         open={isOpenModal} 
         onClose={()=>setIsOpenModal(false)}
         title=""
         callback = {callbackForModal}
         Icon = {CloseIcon}
         ButtonText=''
      >
         
      </Modal>
      }

      {isMobile? 
               <MModal 
                     open={isOpenModalRepost} 
                     callback={callbackrep}
                     onClose={()=>setIsOpenModalRepost(false)}
                     ButtonText='Поделиться'
                     >
                     <div className='modal__children' style={{position:"relative", top:"50px"}}>
                        <RepostWidget style={{top:"50px", position:"relative"}} post={{text,postId,image,senderId}} profile={profile} setIsOpenModalRepost={setIsOpenModalRepost} />
                     </div>
               </MModal>
            :
            <Modal
                     open={isOpenModalRepost} 
                     callback={callbackrep}
                     onClose={()=>setIsOpenModalRepost(false)}
                     title="Поделиться"
                     Icon = {CloseIcon}
                     ButtonText='Поделиться'
                     >
                     <RepostWidget post={{postId}} profile={profile} setIsOpenModalRepost={setIsOpenModalRepost} />
            </Modal> 
            }

      <div className='statusPost'>
      <div className='post bottomWhited' style={{width: "100%"}}>
         <div className="post__avatar">
            {profile.photoURL != ""?
               <Avatar src={`https://localhost:8080/avatar/${profile.photoURL}`} />
            :
               undefined
            }
         </div>
         <div className="post__body">
            <div className="post__header">
               <div className="post__headerText">
                  <div className="post__statusPostHeader">
                     <h3>{profile.fullName} {profile.verified && <VerifiedUserIcon className='post__badge'/>}</h3>
                     <Link to={`/profile/${profile.username}/`}>                    
                     <span className='post__headerSpecial'> 
                        {profile.username && `@${profile.username} `} 
                     </span> 
                     </Link>
                  </div>

                  <div className="post__headerExpandIcon"  aria-describedby={id} variant="contained" onClick={onClickExpand} >
                     <ExpandMoreIcon />
                  </div>
                                          
                  <Popover 
                     id={id}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                     }}
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                     }}
                  >
                     <ul className="post__expandList">
                     {
                        senderId === user._id?
                        <>
                           <li onClick={deleteMyPost}>
                              <div className='delete'><DeleteOutlineIcon /></div><h3 className="delete">Удалить пост</h3>
                           </li>
                        </>
                        :
                        <>
                           <li>
                              <div><SentimentVeryDissatisfiedIcon /></div><h3>Not interested in this tweet</h3>
                           </li>
                           {
                              isFollowing?
                                 <li onClick={unsubscriptre}>
                                 <div><PersonAddDisabledIcon /></div><h3>Unfollow {`@${profile.username}`}</h3>
                                 </li>
                              : <li onClick={subscriptre}>
                                 <div><PersonAddIcon /></div><h3>Follow {`@${profile.username}`}</h3>
                                 </li>
                           }
                        </>
                     }
                     </ul>
                  </Popover>
               </div>
            </div>

         </div>
      </div>

      <div className='statusPost__body'>
         <div className='statusPost__body--message'>{text}</div>
            { image && <img src={image} alt={image}/>}
      </div>

      <div className="statusPost__footer">
         <div className="post__footer">
            <FooterIcon Icon={ChatBubbleOutlineIcon} value={comments.length} onClick={()=>setIsOpenModal(true)}/>
            <Like likes={likes} 
                  likeAction= {()=>like()}
                  unlikeAction = {()=>unlike()}
            />
         </div>         
      </div>

      </div>
   </>
   )
}

export default StatusPost