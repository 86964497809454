import React, {useEffect, useState,useRef} from 'react'
import './Chat.css'

const ChatWelcome = ({isMobile,setHidden}) => {
    useEffect(()=>{
      setHidden(false)
    },[])
    return (
      <>
        <div className="chat" style={{display: `${isMobile? "none" : ""}`}}>
        </div>
      </>
    )
}

export default ChatWelcome