import React from 'react';
import { Button, Result } from 'antd';

import "./ResultError.css"

const ResultError = ({error})=>{
    React.useEffect(() => {
        document.title = `Ошибка при обработке запроса`;
    }, []);
    return(
        <div className="result__error" style={{display: `${error? "flex":"none"}`}}>
            <Result
                status="500"
                title="Ошибка при обработке запроса"
                subTitle="Попробуйте позже."
                extra={<Button onClick={()=>window.location.reload()} type="primary">Перезагрузить</Button>}
        />
        </div>
    )
}

export default ResultError;