  import React, { useEffect, lazy, Suspense, useState } from 'react';
  import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
  import Sidebar from './components/Sidebar/Sidebar';
  import SidebarChat from './components/SidebarChat/SidebarChat';
  import Chat from './components/Chat/Chat';
  import Dev from "./components/Dev/Dev";
  import Notification from "./components/Notifications/Notifications";
  import ConversationInfo from './components/ConversationInfo/ConversationInfo';
  import Login from './components/Login/Login';
  import Menu from './components/Menu/Menu';
  import Edit from "./components/ProfileEdit/ProfileEdit";
  import Widgets from './components/Widgets/Widgets';
  import Jobs from './components/Jobs/Jobs';
  import NotFound from "./components/NotFound/NotFound";
  import PageTransition from './PageTransition';
  import Music from "./components/Music/Music";
  import Settings from './components/Settings/Settings';
  import ProfileFollow from './components/ProfileFollow/ProfileFollow';
  import Status from './components/Status/Status';
  import PageBanned from "./components/PageBanned/PageBanned";
  import CommentThread from './components/CommentThread/CommentThread';
  import Subscription from "./components/Subscription/Subscription";
  import BottomNav from './elements/BottomNav/BottomNav';
  import ThemeChange from "./components/ThemeChange/ThemeChange";
  import './App.css';
  import ChatContextProvider from './contexts/ChatContextProvider';
  import { useStateValue } from './contexts/StateContextProvider';
  import { useMediaQuery } from 'react-responsive';
  import { actionTypes } from './contexts/StateReducers';
  import io from "socket.io-client";
  import ResultError from './components/ResultError/ResultError';
  import ChatWelcome from './components/Chat/ChatWelcome';
  import SplashScreen from './components/SplashScreen/SplashScreen ';
  import Balance from "./components/Balance/Balance";
  import ProfileGroups from './components/ProfileGroups/ProfileGroups';

  const Profile = lazy(() => import('./components/Profile/Profile'));
  const ProfileWidgets = lazy(() => import('./components/ProfileWidgets/ProfileWidgets'));
  const Feed = lazy(() => import('./components/Feed/Feed'));

  const AppContent = () => {
    const [{ user }, dispatch] = useStateValue();
    const location = useLocation();
    const [socket, setSocket] = useState(null);
    const [open, setOpen] = useState(false);
    const [welcome, setWelcome] = useState(true);
    const [error, setError] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 914 });
    const [hidden, setHidden] = useState(false);
    const [isOpenModalinfo, setIsOpenModalinfo] = useState(false);

    useEffect(() => {
      dispatch({
        type: actionTypes.SET_USER,
        user: JSON.parse(localStorage.getItem('twittie_user')),
      });
    }, [dispatch]);

    useEffect(() => {
      if (user) {
        const srv = io.connect('https://localhost:8080/', {
          transports: ['websocket', 'polling'],
          withCredentials: true,
          reconnection: true,
          reconnectionAttempts: Infinity,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          timeout: 20000,
          secure: true,
          query: {
            userId: user._id,
          },
        });

        setSocket(srv);

        return () => {
          srv.close();
        };
      }
    }, [user]);

    return (
      <div className="app">
        {user ? (
          <>
              <ResultError error={error} />
              <ThemeChange setOpen={setOpen} open={open} />
              <BottomNav socket={socket} hidden={hidden} setIsOpenModalinfo={setIsOpenModalinfo} />
              <Menu isOpenModalinfo={isOpenModalinfo} setIsOpenModalinfo={setIsOpenModalinfo} />
              <PageTransition>
                <div className="app__mainContent">
                  <Sidebar setOpen={setOpen} socket={socket} />
                 <ChatContextProvider>
                  
                  <Switch location={location}>
                    <Route exact path="/">
                      <div className="app__main">
                        <Suspense fallback={<p></p>}>
                          <Feed socket={socket} setError={setError} id={user.id} />
                        </Suspense>
                        <Widgets />
                      </div>
                    </Route>
                    <Route exact path="/balance">
                      <div className="app__main">
                        <Balance />
                        <Widgets />
                      </div>
                    </Route>
                    <Route path="/music">
                      <div className="app__main">
                        <Music />
                      </div>
                    </Route>
                    <Route path="/settings">
                      <div className="app__main">
                        <Settings />
                      </div>
                    </Route>
                    <Route path="/subscription">
                      <div className="app__main">
                        <Subscription />
                      </div>
                    </Route>
                    <Route path="/notifications">
                      <div className="app__main">
                        <Notification socket={socket} />
                      </div>
                    </Route>
                    <Route path="/messages">
                      <div className="app__main">
                        <BottomNav socket={socket} hidden={hidden} setIsOpenModalinfo={setIsOpenModalinfo} />
                        <SidebarChat setWelcome={setWelcome} socket={socket} />
                        {welcome ? <ChatWelcome setHidden={setHidden} isMobile={isMobile} /> : undefined}
                        <Switch location={location}>
                          <Route path="/messages/:roomId" exact>
                            <Chat setHidden={setHidden} socket={socket} />
                          </Route>
                          <Route path="/messages/:roomId/info">
                            <ConversationInfo />
                          </Route>
                        </Switch>
                      </div>
                    </Route>
                    <Route path="/groups">
                      <div className="app__main">
                        <Suspense fallback={<p></p>}>
                          <ProfileGroups setHidden={setHidden} />
                        </Suspense>
                      </div>
                    </Route>
                    <Route path="/profile/:username">
                      <div className="app__main">
                        <Suspense fallback={<p></p>}>
                          <Profile setHidden={setHidden} socket={socket} />
                        </Suspense>
                      </div>
                    </Route>
                    <Route path="/dev">
                      <div className="app__main">
                        <Dev />
                      </div>
                    </Route>
                    <Route path="/frieds/:username">
                      <ProfileFollow />
                    </Route>
                    <Route path="/status/:postId">
                      <div className="app__main">
                        <Switch location={location}>
                          <Route path="/status/:postId" exact>
                            <Status />
                          </Route>
                          <Route path="/status/:postId/:commentId">
                            <CommentThread />
                          </Route>
                        </Switch>
                      </div>
                    </Route>
                    <Route path="/jobs">
                      <Jobs />
                    </Route>
                    <Route path="*" component={NotFound} />
                  </Switch>
                </ChatContextProvider>
              </div>
                </PageTransition>
            </>
        ) : (
          <Login />
        )}
      </div>
    );
  };

  const App = () => (
    <Router>
        <AppContent />
    </Router>
  );

  export default App;
