import React, {useState} from 'react'
import SearchWidget from '../../elements/SearchWidget/SearchWidget'
import './Widgets.css'

const Widgets = () => {
   const [text, setText] = useState('')
    return (
        <div className='widgets'>
        </div>
    )
}

export default Widgets
