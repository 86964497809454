import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import App from './App'
import store from './store';
import StateContextProvider from './contexts/StateContextProvider'
import IsRoomOpenedContextProvider from './contexts/IsRoomOpenedContextProvider'

ReactDOM.render(
    <StateContextProvider>
        <IsRoomOpenedContextProvider>
            <Provider store={store}>
                <App/>
            </Provider>
        </IsRoomOpenedContextProvider>
    </StateContextProvider>, 
document.querySelector('#root'))
