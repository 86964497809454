import { SET_MODAL_INFO_OPEN, SET_MODAL_INFO_CLOSE } from './modalActions';

const initialState = {
  isModalInfoOpen: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_INFO_OPEN:
      return {
        ...state,
        isModalInfoOpen: true,
      };
    case SET_MODAL_INFO_CLOSE:
      return {
        ...state,
        isModalInfoOpen: false,
      };
    default:
      return state;
  }
};

export default modalReducer;
