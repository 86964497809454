import React from "react";

const Track = React.memo(({ track, index, playSong }) => {
  console.log("Track")
  return (
    <div className="track" onClick={() => playSong(index)}>
      <div className="track_content">
        <div className="track_cover">
          <img src={`${track.cover}`} alt={`Cover for ${track.name}`} />
        </div>
        <div className="track_artist">
          <span style={{ fontSize: "14.5px", height: "0px" }}>{track.name}</span>
          <span style={{ fontSize: "11px", color: "rgb(170,170,170)" }}>{track.artist}</span>
        </div>
      </div>
    </div>
  );
});

export default Track;