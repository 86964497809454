import React, {useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import Badge from '@mui/material/Badge';
import {Avatar} from '@material-ui/core'
import WidgetsIcon from '@material-ui/icons/Widgets';
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import {useStateValue} from '../../contexts/StateContextProvider'


import './BottomNav.css'


const BottomNav = ({hidden,socket,setIsOpenModalinfo}) => {
  const [messageCount, setMessageCount] = useState(0);
  const [{user}, dispatch] = useStateValue()
 
  useEffect(() => {
    if (socket) {
      socket.emit('getUnreadCount', user._id);

      const handleUnreadCount = (count) => {
        setMessageCount(count);
        console.log(count);
      };

      socket.on('unreadCount', handleUnreadCount);

      // Удаление обработчика при размонтировании
      return () => {
        socket.off('unreadCount', handleUnreadCount);
      };
    }
  }, [socket, user._id]);

  console.log(hidden)

  return (
    <>
      {hidden?
        null
      :
      <div className="bottomNav">
          <nav>
            <Link to='/' activeClassName='bottomNav__active'><HomeIcon /></Link>
            <div onClick={()=>setIsOpenModalinfo(true)}><WidgetsIcon style={{width:"70px"}} /></div>
            <Link to='/messages' activeClassName='bottomNav__active'>
            <Badge badgeContent={messageCount > 0 ? messageCount : null} color="error" max={99} >
              <MailOutlineIcon />
            </Badge>
            </Link>
          </nav>
      </div>
      }
    </>
    
  )
}

export default BottomNav
