import React, {useEffect, useState} from 'react'

import ReplyIcon from '@material-ui/icons/Reply';

import {useStateValue} from '../../contexts/StateContextProvider'
import './FooterIcon.css'

const Rep = ({RepAction,repcount}) => {
    const [{user}] = useStateValue()
    const [isLiked, setisLiked] = useState(false)

    return (
        <div className="footerIcon_wrapper" onClick={RepAction}>
        <ReplyIcon fontSize='large' style={{cursor:"pointer"}}/>
            <div className="footerIcon__counter">{repcount > 0? repcount : ""}</div>
        </div>
    )
}

export default React.memo(Rep)