import React,{useState,useEffect} from "react"
import { db } from "../../firebase"
import { useHistory } from "react-router-dom";
import "./Settings.css"
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

const Settings = ()=>{
    const history = useHistory()
    return (
        <div className="app__settings">
          <div className='feed' style={{ position: "relative", top: "64px" }}>
                <div className="profile__header">
                    <div className="profile__backArrow" onClick={() => history.goBack()}>
                        <ArrowBackOutlinedIcon />
                    </div>
                    <div className='profile__title'>
                        <h2>Настройки</h2>
                    </div>
                </div>        
            </div>
        </div>
    )
}

export default Settings