import React, {useState,useRef,useEffect,useMemo,useCallback} from 'react'
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll'
import ModalImage from '../../elements/Modal/ModalImage'
import {useHistory} from 'react-router-dom'
import PauseIcon from '@mui/icons-material/Pause';
import Slider from '@mui/material/Slider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';  
import axios from "axios"
import {actionTypes} from '../../contexts/IsRoomOpenedReducers'
import {useRoomState} from '../../contexts/IsRoomOpenedContextProvider'
import "./postItem.css"

import {useStateValue} from '../../contexts/StateContextProvider'
import { Avatar } from '@material-ui/core';

const MessageItem = ({msg,isMobile,timeZone,roomId}) => {
    const audioRef = useRef(null)
    const [{user}] = useStateValue()
    const [open, setOpen] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false);
    const [post,setPost] = useState({})
    const [sender,setSender] = useState({})
    const intervalRef = useRef();
    const [ContextOpen,setContextOpen] = useState(false)
    const [trackProgress, setTrackProgress] = useState(0);
    const [duration, setDuration] = useState("")
    const [{isRoomOpened}, dispatch] = useRoomState()
    const [imgsrc, setImgsrc] = useState('')
    const [voice_url, setVoice_url] = useState("");
    const history = useHistory()
    const {id} = msg
    const image = undefined
    const {message, sended, _id, senderId,type,voice_src,postId,readed} = msg
    const progressBarRef = useRef();
    

    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
  
        intervalRef.current = window.setInterval(() => {
          if (audioRef.current.ended) {
            pause()
          } else {
            setTrackProgress(audioRef.current.currentTime);
          }
        }, [10]);
      }


    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
          setIsPlaying(true);
        }
        startTimer();
    }

    const onScrub = (value) => {
        // Clear any timers already running
      clearInterval(intervalRef.current);
      audioRef.current.currentTime = value;
      setTrackProgress(audioRef.current.currentTime);
    }

   
    const onClickImage = (img) =>{
        setImgsrc(img)
        setOpen(true)        
    }

    const handleClose = () => setOpen(false)


    useEffect(()=>{
        if(voice_src){
            const replace_type = voice_src.replace("webm", "mp4")
            setVoice_url(replace_type)
        }
    },[voice_src])


    useEffect(()=>{
        if(type === "rep"){
            if(postId){
              
            }
        }
    },[type])
    
    const viewprofile = useCallback(() => {
        history.push(`/profile/${sender.username}`);
        dispatch({
            type: actionTypes.CLOSING_ROOM,
        });
    }, [history, sender.username, dispatch]);

    const nextpost = useCallback(() => {
        history.push(`/status/${postId}`);
        dispatch({
            type: actionTypes.CLOSING_ROOM,
        });
    }, [history, postId, dispatch]);

    

    const onLoadedMetadata = useCallback(() => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    }, []);

    const play = useCallback(() => {
        setIsPlaying(true);
        startTimer();
        audioRef.current.play();
    }, [startTimer]);

    const pause = useCallback(() => {
        setIsPlaying(false);
        audioRef.current.pause();
        clearInterval(intervalRef.current);
    }, []);


    useEffect(()=>{
        if(senderId === user.id){
            return
        }else{
            if(readed === false){
                const data = {"id":_id}
              //  axios.post(`http://localhost:5000/api/messages/read`,data,{ 
              //      withCredentials: true,
              //   }).then(res =>{
              //      readed = true
              //   })
            }
        }
    },[msg])


    const items = useMemo(()=>{
        switch (type) {
            case "message":
                return (
                    <div className='chat__item ' key={id} onTouchStart={()=>setContextOpen(true)} onContextMenu={()=>setContextOpen(true)}>
                    <p style={{fontSize: `${isMobile? "small" : "1.55rem"}`,wordBreak:"break-all"}}  className={`chat__message ${senderId === user._id? 'chat__receiver' : "" } `}>
                                {image && <img src={image} alt="testim" onClick={()=>onClickImage(image)}/>}
                                {message && <span style={{position:'relative', left:`${senderId === user.id? "0": "-7px"}`}}>{message}</span>}
                         <div className='chat__timestamp'>
                            {sended}
                            <div style={{position:"relative",left:"5px"}}>
                                {senderId === user._id?
                                    (readed === false? <DoneIcon /> : <DoneAllIcon /> )
                                :
                                    null
                                }
                            </div>
                        </div>  
                    </p>
                    </div>
                )
            case "image":
                return (
                    <div className='chat__item ' key={id}>
                        {message?
                        <p className={`chat__message ${senderId === user._id? 'chat__receiver' : "" } `} style={{display:'flex'}}>
                            {image && <img src={image} alt="testim" onClick={()=>onClickImage(image)} style={{marginBottom:"5px"}}/>}
                            {message && <span>{message}</span>}
                      </p>
                        :
                        <p className={`chat__message ${senderId === user._id? 'chat__receiver' : "" } `} style={{padding:"0",display:'flex'}}>
                            {image && <img src={image} alt="testim" onClick={()=>onClickImage(image)}/>}
                         </p>
                        }
                    </div>
                )
            case "voice":
                return (
                    <div className='chat__item ' key={id}>
                         <p className={`chat__message ${senderId === user._id? 'chat__receiver' : "" } `}>
                            <audio playInline="true" onLoadedMetadata={onLoadedMetadata} src={voice_url} preload='auto' ref={audioRef} ></audio>
                                <div className='voice'>
                                    <div className='voice_toogleplay' style={{position:"relative", top:"3px"}}>
                                    {   isPlaying?
                                        <PauseIcon className="voice_toogle_icon" onClickCapture={()=>pause()} />
                                        :
                                        <PlayArrowIcon  className="voice_toogle_icon" onClickCapture={()=>play()} />
                                    }
                                    </div>
                                    <div className='voice_range' style={{top:"4px"}}>
                                        <input style={{width: "100%"}} type="range" max={duration ? duration : `${duration}`} ref={progressBarRef} value={trackProgress} onMouseUp={onScrubEnd} onKeyUp={onScrubEnd} onChange={(e) => onScrub(e.target.value)}/>
                                    </div>
                                </div>
                        </p>
                    </div>
                )
            case "rep":
                return(
                    <>
                    <div className='chat__item ' key={id}>
                         <p className={`chat__message ${senderId === user.id? 'chat__receiver' : "" } `}>
                        <div className='post_rep'>
                            <div className='post_avthor' onClick={()=>viewprofile()}>
                                <div className='sender_ava'>
                                    <Avatar src={sender.photoURL} />
                                </div>
                                <div className='sender_fullname'>
                                    <span>{sender.displayName}</span>
                                </div>
                            </div>
                            <div className='post_content'>
                                {post === undefined?
                                    null
                                :
                                <span style={{fontSize:"12px"}}>
                                    {post.text?
                                        (post.text.length > 100?
                                            post.text.slice(0,100)
                                        :
                                            post.text
                                        )
                                    :
                                        null
                                    }
                                </span>
                                }
                            </div>
                                <div className='image_content'>
                                    {post.image?
                                        <img src={post.image} />
                                    :
                                        null
                                    }
                                </div>
                            <div className='post_next' onClick={()=>nextpost()}>
                                <span>Открыть запись</span>
                            </div>
                        </div>
                    </p>
                    </div>
                    </>
                )
            default:
                break;
        }
    }, [id, isMobile, senderId, user._id, type, timeZone, sended, message, image, sender, readed, voice_url, duration, trackProgress, post, nextpost, viewprofile]);

    return <>
        <ModalImage open={open}
               onClose={handleClose}
               imgsrc={imgsrc}
        />
        {items}
       
    </>
}

export default React.memo(MessageItem)
