import React,{useEffect, useState} from 'react'
import logo from "./logo.png"
//import TwitterIcon from '@material-ui/icons/Twitter'
import SidebarAccount from '../SidebarAccount/SidebarAccount'
import HomeIcon from '@material-ui/icons/Home'
import Badge from '@mui/material/Badge';
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popover from '@material-ui/core/Popover'
import {NavLink} from "react-router-dom"
import styled from 'styled-components'
import {useStateValue} from '../../contexts/StateContextProvider'
import apiClient from '../../utils/api'
import {useRoomState} from '../../contexts/IsRoomOpenedContextProvider'
import {actionTypes} from '../../contexts/IsRoomOpenedReducers'
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import './Sidebar.css'
import BuildIcon from '@material-ui/icons/Build';
import Notification from './Notification'

const Sidebar = React.memo(({setOpen,socket}) => {
    const [{user}] = useStateValue()
    const [profile,setProfile] = useState({})
    const [notifications,setNotifications] = useState([])
    const [{isRoomOpened}, dispatch] = useRoomState()
    const closeAllRooms= () => {
        dispatch({
            type: actionTypes.CLOSING_ROOM,  
        })
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const onClickExpand= (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const [messageCount, setMessageCount] = useState(0);
    const open = Boolean(anchorEl)
    const [mounted,setMounted] = useState(false)
    const id = open ? 'post-popover' : undefined

    useEffect(()=>{
        const fetch = async ()=>{
            const [userRes,NotificationRes] = await Promise.all([
                apiClient.get("/api/users/current",{ withCredentials: true}),
                apiClient.get("/api/notifications/",{ withCredentials: true })
            ])
            setProfile(userRes.data)
            setNotifications(NotificationRes.data)
        }
        fetch()
    },[])

    const deleteNotification = ({id})=>{
        const filtered = notifications.filter((notification) => notification._id !== id)
        setNotifications(filtered)
    }

    useEffect(()=>{
        if(socket){
            socket.on("newNotfication", (NewNotification)=>{
                setNotifications(notifications => ([...notifications, NewNotification]));
            })
        }
    },[socket])

    useEffect(() => {
        if (socket) {
          socket.emit('getUnreadCount', user._id);
    
          const handleUnreadCount = (count) => {
            setMessageCount(count);
          };
    
          socket.on('unreadCount', handleUnreadCount);
        }
      }, [socket, user._id]);


    const Container = styled.div`
        width: 450px;
        height: 400px;
        left: 70px;
        background: var(--background);
        border-radius: 15px;
        position: fixed;
        overflow-y:scroll;
        top:40px;
        -ms-overflow-style: none;
        scrollbar-width: none;  
        z-index: 191;
        border:1px solid var(--twittie-background-posterror)
    `

    const Notifications = styled.div`
        width:100%;
        height:80px;
        position:relative;
    `

    const NotificationNotFound = styled.div`
        color:white;
        width: 100%;
        font-size: 13px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    `

    return (
    <>
        <div className='sidebar'>
           <img src={logo} className='sidebar__twitterIcon'/>
           <NavLink to="/" className='sidebarOption'><HomeIcon /></NavLink>
           <div onClick={onClickExpand }     style={{cursor:"pointer"}} className='sidebarOption' activeClassName='sidebar__active'><NotificationsIcon /></div>
           <NavLink to={`/frieds/${profile.username}`} className='sidebarOption' activeClassName='sidebar__active'><PeopleIcon /></NavLink>
           <NavLink to={`/music`} className='sidebarOption' activeClassName='sidebar__active'><MusicNoteIcon /></NavLink>
           <NavLink to="/messages" className='sidebarOption' activeClassName='sidebar__active' onClick={closeAllRooms}>
            <Badge badgeContent={messageCount > 0 ? messageCount : null} color="error" max={99} >
              <MailOutlineIcon />
            </Badge>
           </NavLink>
           <NavLink to={`/profile/${profile.username}`} className='sidebarOption' activeClassName='sidebar__active' ><PermIdentityIcon /></NavLink>
           {profile.developer?
                <NavLink to="/dev" className='sidebarOption' activeClassName='sidebar__active' ><BuildIcon /></NavLink>
            :
                undefined
            }
           <div style={{ 
            bottom: "0px",
            position: "absolute",
            }}>
                <SidebarAccount setOpen={setOpen} />
           </div>
        </div>
        <Popover 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                style={{
                    transform:'translate(2rem, -1rem)'
                }}
                >
                <Container>
                    <Notifications>
                        {notifications.length < 1?
                            <NotificationNotFound>
                                <h2>Нету уведомлений</h2>
                            </NotificationNotFound>
                        :
                            notifications.map((notification)=>{
                                return(
                                    <Notification setNotifications={setNotifications} deleteNotification={deleteNotification} notification={notification} />
                                )
                            })
                        }
                    </Notifications>
                </Container>
        </Popover>
    </>
    )
});

export default Sidebar
