import React,{memo} from 'react'

const StatusInput = memo(({type, accept, name, id, htmlFor, value,Icon, onChange, onClick}) => {
    return (
        <div onClick={onClick}>
            <input type={type} accept={accept} multiple  name={name} id={id} style={{display:'none'}} onChange={onChange}/>
            <label htmlFor={id}>
                <Icon />
            </label>                                
        </div>
    )
})

export default React.memo(StatusInput)
